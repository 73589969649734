// Team API

import { ApiCore } from './utilities/core'
import { apiProvider } from './utilities/provider';

const url = 'lookup_all_teams.php';
const plural = 'teams';
const single = 'team';

// plural and single may be used for message logic if needed in the ApiCore class.

const apiTeams = new ApiCore({
  getAll: false,
  getSingle: false,
  post: false,
  put: false,
  patch: false,
  delete: false,
  url: url,
  plural: plural,
  single: single
});

// custom api functions

/** @param {int} leagueId */ 
/** @summary Get all teams for a given leagueId */
apiTeams.getAllForLeague = (leagueId) => {
  return apiProvider.getAll(`${url}/?id=${leagueId}`);
}

/** @param {int} teamId */ 
/** @summary Get teamDetails by id */
apiTeams.getById = (teamId) => {
  return apiProvider.getAll(`lookupteam.php?id=${teamId}`);
}

export default apiTeams;