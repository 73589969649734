// Bet API

import { ApiCore } from './utilities/core'
import { apiProvider } from './utilities/provider';

const url = 'chats';
const plural = 'chats';
const single = 'chat';

// plural and single may be used for message logic if needed in the ApiCore class.

const apiChats = new ApiCore({
  getAll: true,
  getSingle: true,
  post: true,
  put: false,
  patch: false,
  delete: false,
  url: url,
  plural: plural,
  single: single
});

/** @summary Get all bets for given user */
apiChats.getToken = (token) => {
  console.log("token");
  console.log({token});
  const tempUrl = `${url}/token`;
  console.log({tempUrl});
  return apiProvider.getAll(tempUrl, token);
}

/** @param {String} user_id */ 
/** @summary Create converstaion with user_id */
apiChats.initConversation = (user_id, token) => {
  return apiProvider.post(`${url}/${user_id}/create`, {}, token);
}

export default apiChats;