// USers API

import { ApiCore } from './utilities/core'
import { apiProvider } from './utilities/provider';

const url = 'users';
const plural = 'users';
const single = 'user';

// plural and single may be used for message logic if needed in the ApiCore class.

const apiUsers = new ApiCore({
  getAll: false,
  getSingle: true,
  post: false,
  put: true,
  patch: false,
  delete: false,
  url: url,
  plural: plural,
  single: single
});


/** @summary Get all bets for given user */
apiUsers.getMyUser = (token) => {
  return apiProvider.getAll(`${url}`, token);
}

/** @summary Get all unsettled bets for given user */
apiUsers.getUnsettled = (user_id, token) => {
  return apiProvider.getAll(`${url}/${user_id}/unsettled`, token);
}

/** @summary Get all closed bets for given user */
apiUsers.getClosed = (user_id, token) => {
  return apiProvider.getAll(`${url}/${user_id}/closed`, token);
}

/** @summary Get presigned URL for uploading user picture */
/** @param {string} filename */ 
apiUsers.getPresignedUrl = (filename, token) => {
  return apiProvider.post(`${url}/picture`, {picture_key: filename}, token);
}

export default apiUsers;