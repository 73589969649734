import React, { useEffect, useState } from 'react';
import {
  Avatar,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  Paper,
  Typography,
  TextField,
  CardHeader,
  CircularProgress,
  LinearProgress,
  Checkbox,
  FormControlLabel
} from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { RulesDialog } from '../account/Register';

import apiUsers from '../services/api/users';
import apiReferral from '../services/api/referral';
import useAuth from '../Auth';
import useToast from '../Toast';
import { Link } from 'react-router-dom';
import { APP_VERSION } from '../App';
import apiChats from '../services/api/chats';
import { useHistory } from 'react-router-dom';

export const NULL_USER = {
  email: '',
  display_name: '',
  venmo_uri: '',
  zelle_uri: '',
  cash_uri: '',
  paypal_uri: '',
  groupme: '',
  picture: '',
  send_email_notifs: false,
}

export function UserCardHeader({_id, display_name, picture, venmo_uri, zelle_uri, cash_uri, paypal_uri}) {
  function getPaymentMethods() {
    let methods = [];
    if (venmo_uri) {
      methods.push("venmo")
    }
    if (zelle_uri) {
      methods.push("zelle")
    }
    if (cash_uri) {
      methods.push("cash app")
    }
    if (paypal_uri) {
      methods.push("paypal")
    }
    // console.log(methods);
    return methods;
  }

  let methods = getPaymentMethods().join(', ');
  return (
    <CardHeader
      avatar={
        <Avatar component={Link}
        to={_id ? `/user/${_id}` : '/account'} src={picture} alt={display_name} />
      }
      title={display_name}
      subheader={methods ? `Accepts ${methods}` : ''}
    />
  )
}

export function UserCard({user}){
  let auth = useAuth();
  const toast = useToast()
  const history = useHistory();

  const initiateChat = () => {
    apiChats.initConversation(user._id, auth.user.token).then(res => {
      console.log({res});
      if (res.chat_id) {
        history.push(`/conversations/${res.chat_id}`);
      } else {
        toast.setMessage("Could not start chat with user.");
        toast.setShow(true);
      }
    })
  }
  
  return (
    <Card>
      {/* just pass in all user props for now, they shoud all be there */}
      <UserCardHeader {...user}/>
      <CardContent>
      {/* if not current user, allow initiate chat */}
      {user._id !== auth.user._id && (
        <Button variant='outlined' onClick={initiateChat}>Chat Now</Button>
      )}
      {/* Rest of profile */}
      { user.groupme && (
        <React.Fragment>
          <Typography color="textSecondary">GroupMe: <b>{user.groupme}</b></Typography><br/>
        </React.Fragment>
      )}
      <Typography variant="overline">Payment Methods</Typography>
        { user.venmo_uri && (
          <React.Fragment>
            <Typography color="textSecondary">Venmo: <b>{user.venmo_uri}</b></Typography>
          </React.Fragment>
        )}
        { user.zelle_uri && (
          <React.Fragment>
            <Typography color="textSecondary">Zelle: <b>{user.zelle_uri}</b></Typography>
          </React.Fragment>
        )}
        { user.cash_uri && (
          <React.Fragment>
            <Typography color="textSecondary">Cash App: <b>{user.cash_uri}</b></Typography>
          </React.Fragment>
        )}
        { user.paypal_uri && (
          <React.Fragment>
            <Typography color="textSecondary">PayPal: <b>{user.paypal_uri}</b></Typography>
          </React.Fragment>
        )}
      </CardContent>
    </Card>
  )
}

function MyAccount(props) {
  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [user, setUser] = useState(NULL_USER);
  const [originalUser, setOriginalUser] = useState(NULL_USER);
  const [selectedFile, setSelectedFile] = useState(null);
  const [showRules, setShowRules] = useState(false);
  const [showContactDialog, setShowContactDialog] = useState(false);
  const [referralEmail, setReferralEmail] = useState('');
  const [sendingReferral, setSendingReferral] = useState(false);

  const toast = useToast();
  const auth = useAuth();

  useEffect(() => {
    _getMyUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  function _getMyUser() {
    setLoading(true);
    apiUsers.getMyUser(auth.user.token).then(res => {
      let user = res.user;
      delete user._id;
      console.log('user: ', user);
      setUser(user);
      setOriginalUser(user);
      setLoading(false);
    })
  }

  function updateUser(user) {
    let updatedUser = {};
    // only update fields from NULL_USER
    Object.keys(NULL_USER).map(key => (updatedUser[key] = user[key]))
    console.log("updating user to: ", updatedUser);
    setLoading(true);
    apiUsers.put(updatedUser, auth.user.token).then(res => {
      console.log(res);
      setLoading(false);
      toast.setMessage("Your account has been updated");
      toast.setShow(true);
      // refresh
      _getMyUser();
    })
  }

  function validateEmail(email) {
    if (typeof email !== "undefined") {
      var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
      if (!pattern.test(email)) {
        return false;
      } else {
        return true;
      }
    } else {
      return false
    }
  }

  function sendReferral() {
    const body = {
      "invitee": referralEmail
    };
    setSendingReferral(true);
    apiReferral.post(body, auth.user.token).then(res => {
      console.log(res);
      setLoading(false);

      if (res.ok) {
        let data = res.data;
        console.log('data: ', data);
        toast.setMessage(`Referral email sent to ${referralEmail}`);
        toast.setShow(true);
        // refresh
        setReferralEmail('');
        setSendingReferral(false);
      } else {
        console.log("error:");
        console.log(res);
        toast.setMessage(`ERROR: Could not send referral to ${referralEmail}`);
        toast.setShow(true);
        setSendingReferral(false);
      }
    })
  }

  function validateSave(user) {
    if (!user.venmo_uri && !user.zelle_uri && !user.cash_uri && !user.paypal_uri) {
      toast.setMessage("Save error: At least one payment URL required.");
      toast.setShow(true);
      return;
    }
    updateUser(user)
  }

  function uploadPicture() {
    setUploading(true);
    if (!selectedFile || !selectedFile.length) {
      toast.setMessage("No file selected");
      toast.setShow(true);
      return;
    }
    let new_file = selectedFile[0];
    console.log(new_file);
    apiUsers.getPresignedUrl(new_file.name, auth.user.token).then(res => {
      let response = res.response;
      console.log("getPresignedUrl response:", response);
      if (response) {
        const picture_url = `${response.url}${response.fields.key}`
        console.log('picture_url: ', picture_url);
        let data = new FormData();
        for (var key in response.fields) {
          console.log(key, response.fields[key]);
          data.set(key, response.fields[key]);
        }
        data.set('file', new_file)
        console.log("form data: ", data);
        // manually upload to URL given
        fetch(response.url, {
          method: 'POST',
          // headers: {'content-type': 'multipart/form-data'},
          body: data
        }).then(res => {
          // clear picture
          setSelectedFile(null);
          console.log("fetch POST res: ", res);
          updateUser({picture: picture_url});
          setUploading(false);
        })
      } else {
        toast.setMessage("Could not upload picture, please try again later.")
        setUploading(false);
      }
    })
  }

  function openContactMailTo() {
    window.location = `mailto:contact@bmbbetting.com?subject=BMB Contact`;
}

  function closeContactDialog() {
    setShowContactDialog(false);
  }

  function renderContactDialog() {
    return (
      <Dialog
        open={showContactDialog}
        onClose={closeContactDialog}
      >
        <DialogTitle >{"Contact us?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Would you like to contact us for assistance? 
            If you continue you will be redirect to a contact email.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeContactDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={openContactMailTo} color="primary" autoFocus>
            Start Email
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  return (
    <React.Fragment>
    {renderContactDialog()}

    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper style={{padding: 12, marginBottom: 12}}>
          <Typography variant={'overline'}>How Others See You</Typography>
          <UserCard user={user}/>
        </Paper>
      </Grid>

      <Grid item xs={12}>
        <Paper style={{padding: 12, marginBottom: 12}}>
          <Typography variant={'overline'}>Profile Stats</Typography>
          <TableContainer component={Paper}>
            <Table aria-label="profile stats">
              <TableHead>
                <TableRow>
                  <TableCell colSpan={2}>Stat</TableCell>
                  <TableCell align="right">Amount</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                  <TableRow >
                    <TableCell colSpan={2} component="th" scope="row">
                      <b>Maximum At Risk</b>
                    </TableCell>
                    <TableCell align="right"><b>${user.open_bet_max && user.open_bet_max.toFixed(2)}</b></TableCell>
                  </TableRow>
                  <TableRow >
                    <TableCell colSpan={2} component="th" scope="row">
                      <b>Total At Risk</b>
                    </TableCell>
                    <TableCell align="right"><b>${(user.filled_bet_amount + user.unfilled_bet_amount + user.unsettled_bet_amount_lost).toFixed(2)}</b></TableCell>
                  </TableRow>
                  <TableRow >
                    <TableCell/>
                    <TableCell component="th" scope="row">
                      Filled Bets
                    </TableCell>
                    <TableCell align="right">${user.filled_bet_amount && user.filled_bet_amount.toFixed(2)}</TableCell>
                  </TableRow>
                  <TableRow >
                    <TableCell/>
                    <TableCell component="th" scope="row">
                      Unfilled Bets
                    </TableCell>
                    <TableCell align="right">${user.unfilled_bet_amount && user.unfilled_bet_amount.toFixed(2)}</TableCell>
                  </TableRow>
                  <TableRow >
                    <TableCell/>
                    <TableCell component="th" scope="row">
                      Unsettled Losses
                    </TableCell>
                    <TableCell align="right">${user.unsettled_bet_amount_lost && user.unsettled_bet_amount_lost.toFixed(2)}</TableCell>
                  </TableRow>
                  <TableRow >
                    <TableCell colSpan={2} component="th" scope="row">
                     <b>Available to Bet</b>
                    </TableCell>
                    <TableCell align="right"><b>${(user.open_bet_max - user.filled_bet_amount - user.unfilled_bet_amount - user.unsettled_bet_amount_lost).toFixed(2)}</b></TableCell>
                  </TableRow>
                  <TableRow >
                    <TableCell colSpan={2} component="th" scope="row">
                      Closed Bets
                    </TableCell>
                    <TableCell align="right">${user.closed_bet_amount && user.closed_bet_amount.toFixed(2)}</TableCell>
                  </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <LinearProgress
            variant={'determinate'}
            value={100*((user.open_bet_amount)/user.open_bet_max)}
          />
        </Paper>
      </Grid>

      <Grid item xs={12}>
        <Paper style={{padding: 12, marginBottom: 12}}>
          <Typography variant="overline">Update Profile Picture</Typography>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <input type="file" accept="image/*" onChange={(e)=>setSelectedFile(e.target.files)}></input>
            </Grid>
            <Grid item xs={12}>
              <Button 
                disabled={!selectedFile || !selectedFile.length || uploading}
                onClick={uploadPicture}
              >
                Upload New Picture
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Grid>

      <Grid item xs={12}>
        <Paper style={{padding: 12, marginBottom: 12}}>
          <Grid item xs={12}>
            <Typography variant={'overline'}>Refer Your Friends</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              label="Enter email"
              value={referralEmail}
              onChange={(e) => setReferralEmail(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <Button 
                disabled={!referralEmail || sendingReferral || !validateEmail(referralEmail)}
                onClick={sendReferral}
              >
                Send Referral Link
              </Button>
          </Grid>
        </Paper>
      </Grid>

      <Grid item xs={12}>
        { loading ? (
          <CircularProgress />
        ) : (
          <Paper style={{padding:12}}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant={'overline'}>Update Profile</Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  required
                  label="Email"
                  value={user.email || ''}
                  onChange={(e)=>setUser({...user, email: e.target.value})}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControlLabel 
                  label="Enable email notifications for bet activity"
                  control={
                    <Checkbox
                      required
                      color="primary"
                      checked={user.send_email_notifs || false}
                      onChange={(e)=>setUser({...user, send_email_notifs: e.target.checked})}
                    />
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField 
                  required
                  label="Display Name"
                  value={user.display_name || ''}
                  onChange={(e)=>setUser({...user, display_name: e.target.value})}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="GroupMe"
                  value={user.groupme || ''}
                  onChange={(e)=>setUser({...user, groupme: e.target.value})}
                />
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <Typography variant={'overline'}>Payment Methods</Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  required
                  label="Venmo Profile"
                  value={user.venmo_uri || ''}
                  onChange={(e)=>setUser({...user, venmo_uri: e.target.value})}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  required
                  label="Zelle Profile"
                  value={user.zelle_uri || ''}
                  onChange={(e)=>setUser({...user, zelle_uri: e.target.value})}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  required
                  label="Cash App Profile"
                  value={user.cash_uri || ''}
                  onChange={(e)=>setUser({...user, cash_uri: e.target.value})}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  required
                  label="PayPal Profile"
                  value={user.paypal_uri || ''}
                  onChange={(e)=>setUser({...user, paypal_uri: e.target.value})}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Button
                  disabled={user===originalUser}
                  onClick={()=>validateSave(user)}
                >
                  Save Profile
                </Button>
              </Grid>
            </Grid>
          </Paper>
        )}
      </Grid>
      <Grid item xs={12}>
        <RulesDialog open={showRules} disabled={true} onClose={()=>setShowRules(false)} />
        <Button onClick={()=>setShowRules(true)}>App Rules & Instructions</Button>
      </Grid>
      <Grid item xs={12}>
        <Button onClick={()=>setShowContactDialog(true)}>Contact Us</Button>
      </Grid>
      <Grid item xs={12}>
        <Typography variant={'caption'}>v{APP_VERSION}</Typography>
      </Grid>
    </Grid>
    </React.Fragment>
  )
}

export default MyAccount;