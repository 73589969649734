import { 
  createContext,
  useContext,
  useState,
  useRef,
  useEffect
} from "react";

const conversationsContext = createContext();

export default function useConversations() {
  return useContext(conversationsContext);
}

export function useProvideConversations() {
  const [conversations, setConversations] = useState([]);
  const [currConvoSid, setCurrConvoSid] = useState("");
  const currConvoSidRef = useRef("");
  const [hasUnreadMessages, setHasUnreadMessages] = useState(false);
  const [conversationStatus, setConversationStatus] = useState("");

  function checkHasUnreadMessage() {
    console.log({conversations}, 'checkHasUnreadMessage()');
    setHasUnreadMessages(false);
    for (let i=0; i<conversations.length; i++) {
      console.log(conversations[i], 'checkHasUnreadMessage conversation object')
      conversations[i].getUnreadMessagesCount()
        .then(count => {
          if (count > 0) {
            setHasUnreadMessages(true);
          }
        })
        .catch(err => {
          console.error("Couldn't fetch unread messages IMPLEMENT RETRY", err);
        });
    }
  }

  useEffect(() => {
    currConvoSidRef.current = currConvoSid;
  }, [currConvoSid]);

  return {
    conversations,
    setConversations,
    currConvoSid,
    setCurrConvoSid,
    currConvoSidRef,
    hasUnreadMessages,
    setHasUnreadMessages,
    conversationStatus,
    setConversationStatus,
    checkHasUnreadMessage
  };
}

export function ProvideConversations({ children }) {
  const convo = useProvideConversations();
  return (
    <conversationsContext.Provider value={convo}>
      {children}
    </conversationsContext.Provider>
  )
}