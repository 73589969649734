import React, { useEffect, useState } from 'react';
import { 
  Button,
  CircularProgress,
} from '@material-ui/core';
import {
  useParams,
  useHistory
} from "react-router-dom";

import apiBets from '../services/api/bets';
import useAuth from '../Auth';
import useToast from '../Toast';

import { BetStep } from "../newBet/NewBet";

export default function EditBet() {
  const [newBet, setNewBet] = useState({});
  const [loading, setLoading] = useState(false);

  let { betId } = useParams();

  let auth = useAuth();
  const toast = useToast();
  const history = useHistory();

  useEffect(() => {
    if (auth.user.token && betId) {
      setLoading(true);
      apiBets.getSingle(betId, auth.user.token).then(res => {
        let bet = res.bet;
        console.log('bet: ', bet)
        setNewBet(bet);
        setLoading(false);
        if (bet.user_id !== auth.user._id) {
          toast.setMessage("You do not have permission to edit this bet");
          toast.setShow(true);
          history.push(`/bet/${betId}`);
        }
      });
    }
    
  }, [betId, auth.user, history, toast])

  const saveUpdatedBet = () => {
    setLoading(true);
    apiBets.updateBet(betId, newBet, auth.user.token).then(res => {
      if (res.ok) {
        let bet = res.bet;
        console.log('updated bet: ', bet)
        setNewBet(bet);
        setLoading(false);
        toast.setMessage("Your bet has been updated!");
        toast.setShow(true);
        history.push(`/bet/${betId}`);
      } else {
        setLoading(false);
        if (res.message) {
          toast.setMessage(`ERROR: ${res.message}`);
        } else {
          toast.setMessage("Could not update bet");
        }
        toast.setShow(true);
      }
    })
  }

  return (
    <React.Fragment>
      {loading ? (
        <CircularProgress />
      ) : (
        <React.Fragment>
          <BetStep newBet={newBet} setNewBet={setNewBet}/>
          <Button onClick={saveUpdatedBet}>Update Bet</Button>
        </React.Fragment>
      )} 
      
    </React.Fragment>
    
  )
}