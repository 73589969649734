// provider.js

import { handleResponse, handleError } from './response'; 

const BASE_URL = 'https://www.thesportsdb.com/api/v1/json/1'; 

/** @param {string} resource */ 
const getAll = (resource) => { 
  return fetch(`${BASE_URL}/${resource}`) 
    .then(handleResponse) 
    .catch(handleError); 
}; 

/** @param {string} resource */ 
/** @param {string} id */ 
const getSingle = (resource, id) => { 
  return fetch(`${BASE_URL}/${resource}/${id}`) 
    .then(handleResponse) 
    .catch(handleError); 
}; 

/** @param {string} resource */ 
/** @param {object} model */ 
const post = (resource, model) => { 
  return fetch(`${BASE_URL}/${resource}`, 
    {method: 'post', body: JSON.stringify(model)}) 
    .then(handleResponse) 
    .catch(handleError); 
}; 

/** @param {string} resource */ 
/** @param {object} model */ 
const put = (resource, model) => { 
  return fetch(`${BASE_URL}/${resource}`, 
    {method: 'put', body: JSON.stringify(model)}) 
    .then(handleResponse) 
    .catch(handleError); 
}; 

/** @param {string} resource */ 
/** @param {object} model */ 
const patch = (resource, model) => { 
  return fetch(`${BASE_URL}/${resource}`, 
    {method: 'patch', body: JSON.stringify(model)}) 
    .then(handleResponse) 
    .catch(handleError); 
}; 

/** @param {string} resource */ 
/** @param {string} id */ 
const remove = (resource, id) => { 
  return fetch(`${BASE_URL}/${resource}/${id}`, 
    {method: 'delete'})  
    .then(handleResponse) 
    .catch(handleError); 
}; 

export const apiProvider = { 
  getAll, 
  getSingle, 
  post, 
  put, 
  patch, 
  remove, 
};