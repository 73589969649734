import React from 'react';
import Chip from '@material-ui/core/Chip';
import DoneIcon from '@material-ui/icons/Done';

// Dispays Chips, filling with value in filters
function FilterChips({toggleFilter, filters, selectedFilters}) {
  return (
    <React.Fragment>
      {filters.map((filter) => {
        // change style based on if filter is selected or not
        if (selectedFilters.includes(filter.key)) {
          return (
            <Chip 
              clickable
              color="primary"
              icon={filter.icon}
              key={filter.key} 
              label={filter.label} 
              onClick={() => toggleFilter(filter.key)}
              onDelete={() => toggleFilter(filter.key)}
              deleteIcon={<DoneIcon />}
              style={{margin: 6}}
            />
          )
        } else {
          return (
            <Chip 
              clickable
              icon={filter.icon}
              variant="outlined"
              key={filter.key} 
              label={filter.label} 
              onClick={() => toggleFilter(filter.key)}
              style={{margin: 6}}
            />
          )
        }
      })}
    </React.Fragment>
  )
}

export default FilterChips;