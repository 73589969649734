export const SET_NOTIFICATIONS = "SET_NOTIFICATIONS";

export function setNotifications(data) {
    return dispatch => {
        dispatch({
            type: SET_NOTIFICATIONS,
            data: data
        });
    };
}
