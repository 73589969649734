import { combineReducers } from "redux";
import BetReducer from "./BetReducer";
import ConversationReducer from "./ConversationReducer";
import NotificationReducer from "./NotificationReducer";

const RootReducer = combineReducers({
  bet: BetReducer,
  notification: NotificationReducer,
  conversation: ConversationReducer
});

export default RootReducer;
