import { 
  useState, 
  useEffect,
  useContext,
  createContext
} from "react";
import jwt_decode from "jwt-decode";

import apiAuth from './services/api/auth';

/** For more details on
 * `authContext`, `ProvideAuth`, `useAuth` and `useProvideAuth`
 * refer to: https://usehooks.com/useAuth/
 */
export const authContext = createContext();

export default function useAuth() {
  return useContext(authContext);
}

export function useProvideAuth() {
  const [user, setUser] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isAuthenticating, setIsAuthenticating] = useState(false);

  useEffect(() => {
    setIsAuthenticating(true);
    let tempUser = JSON.parse(localStorage.getItem('user'));

    if (tempUser) {
      console.log('User exists: ', tempUser)
      let decodedToken = jwt_decode(tempUser.token);
      console.log("Decoded user.token", decodedToken);
      let decodedRefresh = jwt_decode(tempUser.refresh);
      console.log("Decoded user.refresh", decodedRefresh);

      // always try to refresh tokens, if they are expired user will be logged out
      refreshTokens(tempUser.refresh);

      // // JWT exp is in seconds
      // let currentDate = new Date();
      // if (decodedToken.exp * 1000 < currentDate.getTime()) {
      //   console.log("Token expired.");
      //   setIsAuthenticating(false);
      //   setUser(null)
      // } else {
      //   // refresh access token
      //   refreshTokens(tempUser.refresh);   
      // }
    } else {
      setIsAuthenticating(false);
      console.log('need to authenticate user');
    }    
  }, [])

  const refreshTokens = (token) => {
    console.log("refreshing tokens");
    setIsAuthenticating(true);
    apiAuth.refresh(token).then(res => {
      let user = res.data;
      console.log('user: ', user);
      if (user) {
        localStorage.setItem('user', JSON.stringify(user));
        setUser(user);
        setIsAuthenticating(false);
        console.log('tokens refreshed')
      } else {
        localStorage.setItem('user', null);
        setUser(null);
        setIsAuthenticating(false);
        console.log("invalid login")
      }
    });
  }

  const signin = (data, cb) => {
    setIsAuthenticating(true);
    return apiAuth.post(data).then(res => {
      let user = res.data;
      console.log('user: ', user);
      if (user) {
        localStorage.setItem('user', JSON.stringify(user));
        setIsAuthenticating(false);
        setUser(user);
        cb();
      } else {
        setIsAuthenticating(false);
        if(res.message) {
          setErrorMessage(res.message);
        }
        console.log("invalid login")
      }
    });
  };

  // const refresh = (cb) => {
  //   setIsAuthenticating(true);
  //   return apiAuth.post(data).then(res => {
  //     let user = res.data;
  //     console.log('user: ', user);
  //     if (user) {
  //       localStorage.setItem('user', JSON.stringify(user));
  //       setIsAuthenticating(false);
  //       setUser(user);
  //       cb();
  //     } else {
  //       setIsAuthenticating(false);
  //       console.log("invalid login")
  //     }
  //   });
  // };

  const signout = cb => {
    console.log("Logging user out");
    console.log(cb);
    return () => {
      localStorage.setItem('user', null);
      setUser(null);
      console.log("user logged out")
      cb();
    };
  };

  return {
    user,
    isAuthenticating,
    errorMessage,
    setErrorMessage,
    signin,
    signout
  };
}

export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return (
    <authContext.Provider value={auth}>
      {children}
    </authContext.Provider>
  );
}