import React, { useEffect, useState } from 'react';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import moment from 'moment';

import { Link } from "react-router-dom";

import { UserCardHeader } from '../account/MyAccount';

// Icons
import ShareIcon from '@material-ui/icons/Share';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';

// API
import useToast from '../Toast';
import { calcWinnings } from '../newBet/NewBet';
import { getSport } from '../bet/SportKeys';
import { Chip } from '@material-ui/core';

import useAuth from '../Auth';

export const TeamAvatar = withStyles({
  img: {
    objectFit: 'contain'
  },
  root: {
    justifyContent: "center",
    display: "flex"
  }
})(Avatar);

export function SideDetail({type, winner_team, team_1, team_1_odds, team_2, 
  team_2_odds, spread, points_scored, over_under, take, scores}) {
  
  const [team1Score, setTeam1Score] = useState();
  const [team2Score, setTeam2Score] = useState();
  const [dispTeam1Odds, setDispTeam1Odds] = useState('');
  const [dispTeam2Odds, setDispTeam2Odds] = useState('');

  useEffect(() => {
    console.log({scores})
    if (scores) {
      scores.forEach(score => {
        if (score.name === team_1) {
          setTeam1Score(score.score);
        }
        if (score.name === team_2) {
          setTeam2Score(score.score);
        }
      })
    }
  }, [scores, team_1, team_2])

  useEffect(() => {
    if (Math.abs(team_1_odds) === 100) {
      setDispTeam1Odds('');
    } else {
      setDispTeam1Odds(team_1_odds)
    }
  }, [team_1_odds])

  useEffect(() => {
    if (Math.abs(team_2_odds) === 100) {
      setDispTeam2Odds('');
    } else {
      setDispTeam2Odds(team_2_odds)
    }
  }, [team_2_odds])
  
  return (
    <React.Fragment>
      {type === 'Moneyline' && (
        <React.Fragment>
          <Typography variant="caption" color="primary">
            {(team_2 === winner_team && !take) || (team_1 === winner_team && take) ? (
              <b>{team_2} {dispTeam2Odds} (selected)</b>
            ) : (
              `${team_2} ${dispTeam2Odds}`
            )}
            {team2Score && <span>, pts: {team2Score}</span>}
          </Typography>
          <br/>
          <Typography variant="caption" color="primary">
            {/* invert for taker */}
            {(team_1 === winner_team && !take) || (team_2 === winner_team && take) ? (
              <b>{team_1} {dispTeam1Odds} (selected)</b>
            ) : (
              `${team_1} ${dispTeam1Odds}`
            )}
            {team1Score && <span>, pts: {team1Score}</span>}
          </Typography>
          
        </React.Fragment>
      )}
      {type === 'Spread' && (
        <React.Fragment>
          {/* spread is always for team 1 */}
          <Typography variant="caption" color="primary">
          {(team_2 === winner_team  && !take) || (team_1 === winner_team && take) ? (
            <b>{team_2} {dispTeam2Odds} ({-spread}) (selected)</b>
          ) : (
            `${team_2} ${dispTeam2Odds} (${-spread})`
          )}
          {team2Score && <span>, pts: {team2Score}</span>}
          </Typography>
          <br/>
          {/* invert for taker */}
          <Typography variant="caption" color="primary">
            {(team_1 === winner_team && !take) || (team_2 === winner_team && take) ? (
              <b>{team_1} {dispTeam1Odds} ({spread}) (selected)</b>
            ) : (
              `${team_1} ${dispTeam1Odds} (${spread})`
            )}
            {team1Score && <span>, pts: {team1Score}</span>}
          </Typography>
        </React.Fragment>
      )}
      {type === 'Totals' && (
        <React.Fragment>
          <Typography variant="caption" color="primary">
            {/* invert for taker */}
            {dispTeam1Odds} <b>{(over_under === 'over' && !take) || (over_under === 'under' && take) ? '(O)' : '(U)'} {points_scored} (selected)</b>
            {(team1Score && team2Score)  && <span>, Game Total: {parseInt(team1Score) + parseInt(team2Score)}</span>}
          </Typography>
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

export const makerDidWin = (type, scores, team_1, team_2, winner_team, team_1_odds, team_2_odds, spread, points_scored, over_under) => {
  // get team scores
  let team_1_score = 0;
  let team_2_score = 0;
  if (scores) {
    scores.forEach(score => {
      if (score.name === team_1) {
        team_1_score = parseInt(score.score);
      }
      if (score.name === team_2) {
        team_2_score  = parseInt(score.score);
      }
    });
  }
  // only check "win" conditions
  // branch bet type
  console.log(type, team_1, team_2, team_1_score, team_2_score, "makerDidWin")
  if (type === "Moneyline") {
    // moneyline is simple winner (who scored most points)
    if (winner_team === team_1 && team_1_score > team_2_score) {
      return true;
    } else if (winner_team === team_2 && team_2_score > team_1_score) {
      return true
    } else if (team_1_score === team_2_score) {
      return "push"
    }
  } else if (type === "Totals") {
    // totals adds scores together and compares to selection
    if ((over_under === "over") && points_scored < (team_1_score + team_2_score)) {
      return true;
    } else if ((over_under === "under") && points_scored > (team_1_score + team_2_score)) {
      return true;
    } else if (points_scored === (team_1_score + team_2_score)) {
      return "push";
    }
  } else if (type === "Spread") {
    // bet spread is always for team 1
    if (winner_team === team_1) {
      // team_1 plus spread should be greater than team_2_score
      if (team_1_score + spread > team_2_score) {
        return true;
      }
    } else if (winner_team === team_2) {
      // team_1 plus spread should be less than team_2_score
      if (team_1_score + spread < team_2_score) {
        return true;
      }
    }
    if (team_1_score + spread === team_2_score) {
      // always a push if points even
      return "push";
    }
  }

  // if we got to this point, we know they lost
  return false;
}

// Dispays Event information
export function BetDetails({team_1, team_1_odds, team_2, team_2_odds, winner_team,
  type, spread, points_scored, over_under, datetime, amount, sport_key, take_total, 
  timestamp, scores, completed, my_bet, is_settled, takes, user}) {

  const auth = useAuth();

  useEffect(() => {
    console.log(auth, 'auth object');
    // console.log(user, 'user object');
    // console.log(takes, 'takes object');
  }, [auth]);

  return (
    <Grid container spacing={1}>
      {/* Team Pics on left */}
      <Grid item container sm={2} xs={12} spacing={3}>
        <Grid item sm={12} xs={6}>
          <TeamAvatar
            variant="rounded"
            component={Paper}
            elevation={2}
            alt={team_2}
            src={`/sports/teams/${team_2}.png`}
          >
            {team_2.length > 0 && (team_2.charAt(0))}
          </TeamAvatar>
        </Grid>
        <Grid item sm={12} xs={6}>
          <TeamAvatar 
            variant="rounded"
            component={Paper}
            elevation={2}
            alt={team_1}
            src={`/sports/teams/${team_1}.png`}
          >
            {team_1.length > 0 && (team_1.charAt(0))}
          </TeamAvatar>
        </Grid>
      </Grid>
      <Grid item sm={10} xs={12}>
        <Typography>
          {winner_team === team_2 ? <b>{team_2}</b> : team_2} @ {winner_team === team_1 ? <b>{team_1}</b> : team_1}
        </Typography>
        <Typography variant="overline">
          {moment(datetime).local().format('L, LT')}
        </Typography>
        <br/>
        {sport_key && <Chip icon={getSport(sport_key).icon} label={getSport(sport_key).label} style={{marginRight:6}} /> }
        {type && <Chip label={type} style={{marginRight:6}} /> }
        <br/>
        <Typography variant="overline">
          {completed && (makerDidWin(type, scores, team_1, team_2, winner_team, team_1_odds, team_2_odds, spread, points_scored, over_under) === true ? (
            <Chip style={{marginRight:6}} size="small" color="primary" label={`${user.display_name} won`} variant="outlined"/> 
          ) : (
            makerDidWin(type, scores, team_1, team_2, winner_team, team_1_odds, team_2_odds, spread, points_scored, over_under) === 'push' ? (
              <Chip style={{marginRight:6}} size="small" color="primary" label="push" variant="outlined"/>
            ) : (
              <Chip style={{marginRight:6}} size="small" color="primary" label={`${user.display_name} lost`} variant="outlined"/>
            )
          ))}
          {completed && (<Chip style={{marginRight:6}} size="small" color="primary" label="final" variant="outlined"/>)}
          {take_total > 0 && (<Chip style={{marginRight:6}} size="small" color="primary" label={`$ filled ${takes[0]?.user_display_name && ('by ' + takes[0]?.user_display_name)}`} variant="outlined"/>)}<span> </span>
          <b>${amount} to win ${calcWinnings(team_1, team_1_odds, team_2, team_2_odds, winner_team, type, amount)}</b>
        </Typography>
        <br/>
        <Typography variant="overline">
          Posted: {moment.utc(timestamp).local().format('L, LT')}
        </Typography>
        <br/>
        <SideDetail 
          team_1={team_1} team_1_odds={team_1_odds} team_2={team_2} team_2_odds={team_2_odds} winner_team={winner_team} 
          type={type} spread={spread} points_scored={points_scored} over_under={over_under} scores={scores}
        />
        <br/>
        {completed && takes.length > 0 && (!is_settled || !takes[0]?.is_settled) && <React.Fragment>
          {is_settled ? 
            <Chip label={user._id === auth?.user._id ? `${auth?.user.display_name} has settled` : `${user.display_name} has settled.`} style={{marginRight:6}} /> 
            : 
            <Chip label={user._id === auth?.user._id ? `${auth?.user.display_name} has not settled` : `${user.display_name} has not settled.`} style={{marginRight:6}} />}
          {takes[0]?.is_settled ? 
            <Chip label={takes[0].user_id === auth?.user._id ? `${auth?.user.display_name} has settled` : `${takes[0].user_display_name} has settled.`} style={{marginRight:6}} /> 
            : 
            <Chip label={takes[0].user_id === auth?.user._id ? `${auth?.user.display_name} has not settled` : `${takes[0].user_display_name} has not settled.`} style={{marginRight:6}} />}
        </React.Fragment>}
      </Grid>
    </Grid>
  )
}

export function ReportButton({id}) {
  const [showReportDialog, setShowReportDialog] = useState(false);

  function openReportMailTo() {
    window.location = `mailto:contact@bemybookie.com?subject=Bet Issue&body=bet: ${window.location.protocol}//${window.location.host}/bet/${id}`;
  }

  function closeReportDialog() {
    setShowReportDialog(false);
  }

  return(
    <React.Fragment>
      {/* Dialog */}
      <Dialog
          open={showReportDialog}
          onClose={closeReportDialog}
        >
          <DialogTitle >{"Report This Bet?"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Would you like to report a problem with this bet? 
              If you continue you will be redirect to a contact email.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeReportDialog} color="primary">
              Cancel
            </Button>
            <Button onClick={openReportMailTo} color="primary" autoFocus>
              Report
            </Button>
          </DialogActions>
      </Dialog>
      {/* Button */}
      <Tooltip title="Report a problem with this bet.">
        <IconButton 
          onClick={()=>setShowReportDialog(true)}>
            <ReportProblemIcon />
        </IconButton>
      </Tooltip>
    </React.Fragment>
  )
}

export function ShareButton({id}) {
  const toast = useToast();

  function copyToClipboard(url) {
    try {
      navigator.clipboard.writeText(url)
      toast.setMessage(`Bet link copied to clipboard`);
    } catch {
      toast.setMessage(`Could not copy bet link (in development)`);
    }
      toast.setShow(true);
  }
  return (
    <Tooltip title="Share this bet.">
      <IconButton 
        onClick={() => {
          copyToClipboard(`${window.location.protocol}//${window.location.host}/bet/${id}`)
        }}>
        <ShareIcon />
      </IconButton>
    </Tooltip>
  )
}

// Dispays Bet information
function BetCard({id, user, is_settled, takes, team_1, team_2, 
  winner_team, team_1_odds, team_2_odds, type, 
  spread, points_scored, over_under, datetime, 
  amount, sport_key, show_settled, take_total,
  my_bet, timestamp, scores, completed}) {

  const auth = useAuth();

  // useEffect(() => {
  //   _getTeamDetails(team_id_1, team_id_2)
  // }, [team_id_1, team_id_2])


  return (
    <Card style={{margin:6, padding:6 }}>
      {/* Bet header */}
      {/* user should include all props necessary */}
      <UserCardHeader {...user}/>

      <CardContent>
        {/* Event details */}
        <BetDetails
         datetime={datetime}
         team_1={team_1}
         team_2={team_2}
         team_1_odds={team_1_odds}
         team_2_odds={team_2_odds}
         winner_team={winner_team}
         type={type}
         spread={spread}
         points_scored={points_scored}
         over_under={over_under}
         amount={amount}
         sport_key={sport_key}
         take_total={take_total}
         timestamp={timestamp}
         scores={scores}
         completed={completed}
         my_bet={my_bet}
         is_settled={is_settled}
         takes={takes}
         user={user}
        />
      </CardContent>

      {/* Actions */}
      <CardActions>
        {show_settled ? (
          <React.Fragment>
            <Button component={Link} to={`/settle/${id}`} variant="contained" size="small" color="primary">
              {/* IF my bet and settled, or not my bet and not settled, means I have settles */}
              { ((auth?.user._id === user._id && is_settled) || (auth?.user._id !== user._id && !is_settled)) ? (
                "View Bet"
              ): (
                "Settle Bet"
              )}
            </Button>
            <ReportButton id={id} />
          </React.Fragment>
          
        ) : (
          <React.Fragment>
            <Button component={Link} to={`/bet/${id}`} variant="contained" size="small" color="primary">
              { (my_bet && take_total === 0) ? (
                "Edit Bet"
              ) : (
                "View Bet"
              )}
            </Button>
            <ShareButton id={id} />
            <ReportButton id={id} />
          </React.Fragment>
        )}
      </CardActions>
    </Card>
  )
}

export default BetCard;