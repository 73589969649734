import React, { useEffect, useState } from 'react';
import {
  Button,
  Divider,
  Grid,
  Paper,
  Typography,
  TextField,
  CircularProgress,
  FormControlLabel,
  Checkbox
} from '@material-ui/core';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import apiRegister from '../services/api/register';
import apiRegisterRequest from '../services/api/register-request';
import useAuth from '../Auth';
import useToast from '../Toast';
import { useHistory } from 'react-router-dom';

import { useQuery } from '../utils';

const NULL_USER = {
  email: '',
  display_name: '',
  venmo_uri: '',
  zelle_uri: '',
  cash_uri: '',
  paypal_uri: '',
  groupme: '',
  password: '',
  referral_token: ''
}

function Rules(props) {
  return (
    <React.Fragment>
      <Typography variant="overline">App Instructions</Typography>
      <Typography>
        <ol>
          <li>Read the rules below in full.</li>
          <li>Register and fully complete your profile.</li>
          <li>Create a new bet or view the unfilled betting feed to take a bet.</li>
          <li>View your profile to see or manage your filled and unfilled action, including available funds to
bet.</li>
          <li>Once a bet is completed, work with your counterparty to settle the bet immediately, no later
than 24 hours after the event is completed.</li>
          <li>Once the bet is settled, confirm on your account that the bet is settled. Once both parties
confirm, your available funds to bet will increase by the amount you risked for the settled bet.</li>
          <li>Message us for any questions or issues requiring escalations.</li>
        </ol>
      </Typography>
      <Typography variant="overline">Registration</Typography>
      <Typography>
      Registration is only permitted by going to your account page and sending a unique referral link to somebody's 
      email address. This registration will be traced to the referring member and should there be any unresolved 
      issues, the referring member would be accountable. Therefore, only share this unique link with trusted parties. 
      This is an exclusive betting community; for us, by us.
      </Typography>
      <Typography variant="overline">Limits</Typography>
      <Typography>
        All limits will default to $1000. All open and unsettled bets will count towards the $1000 limit. Limits can
        be increased on an exception basis, based on tenure and betting history. Please message us for limit
        increase requests.
      </Typography>
      <Typography variant="overline">Payment</Typography>
      <Typography>
        It is the responsibility of the two betting parties to settle their own bets. Settlement should happen <u>immediately</u>, but no later than 24 hours after the event is over. Once both parties confirm that the bet
        has been settled, it will disappear from your account and your available balance will increase. Payment
        options are visible in every member’s profile (i.e. Venmo, Zelle, PayPal).
      </Typography>
      <Typography variant="overline">Payment Issues</Typography>
      <Typography>
        If there are any payment issues, please message your counterparty immediately to notify them of
        potential delays. For any items requiring escalation, please message us and we will intervene
        immediately, which can include, but isn’t limited to, requiring your referring member to pay on your
        behalf, reaching out to your family/friends to obtain payment, etc.
      </Typography>
      <Typography variant="overline">Betting</Typography>
      <Typography>
        It is every bettor’s responsibility to confirm the betting side, betting line and betting amount prior to
        posting or taking a bet. We are not responsible for manual errors in bets that have been filled. <u>All filled
        bets are live action</u>. The only way to cancel a previously filled bet is if both parties agree prior to the
        start of the event.
      </Typography>
      <Typography>
        Betting lines change. It is the responsibility of the bettor to update their existing unfilled bets if the line
        changes. We will intervene if there is a major news event (i.e. COVID or injury) that materially impacts a
        betting line and the action was taken on an outdated betting line after the news came out. Please
        escalate to us regarding these situations.
      </Typography>
      <Typography variant="overline">Important Reminder</Typography>
      <Typography>
        Remember that we’re all here to have fun and get action on the games for entertainment. Respect your
        fellow bettors. Enjoy the app. Bad behaviors, such as posting incorrect lines, late or non-payment,
        spamming the message board and spamming the betting feed may result in permanent removal from
        the app and potential action on the referring member.
      </Typography>
    </React.Fragment>
  )
}

export function RulesDialog(props) {
  const [hasRead, setHasRead] = useState(false);

  return (
    <Dialog
      maxWidth={'xl'}
      open={props.open}
      onClose={props.handleClose}
    >
      <DialogTitle>System Rules & Instructions</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Rules />
        </DialogContentText>
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              checked={hasRead || props.disabled}
              disabled={props.disabled}
              onChange={()=>{setHasRead(!hasRead)}}
              name="ackowledge"
            />
          }
          label="I have read and acknowledge the rules"
        />
      </DialogContent>
      <DialogActions>
        <Button 
          disabled={!hasRead && !props.disabled}
          onClick={props.onClose} 
          color="primary"
        >
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  )
}

function Register(props) {
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(NULL_USER);
  const [secondPassword, setSecondPassword] = useState('');
  const [userPhone, setUserPhone] = useState('');
  const [userChannel, setUserChannel] = useState('');
  const [showRules, setShowRules] = useState(true);
  // const [refToken, setRefToken] = useState('');

  const query = useQuery();
  const toast = useToast();
  const auth = useAuth();
  let history = useHistory();


  useEffect(() => {
    let refTokenParam = query.get("refToken") || '';

    // prepopulate any user params passed in
    let emailParam = query.get("email") || '';
    let displayNameParam = query.get("display_name") || '';
    let venmoParam = query.get("venmo_uri") || '';
    let zelleParam = query.get("zelle_uri") || '';
    let cashParam = query.get("cash_uri") || '';
    let paypalParam = query.get("paypal_uri") || '';
    let groupmeParam = query.get("groupme") || '';

    let updatedUser = {
      referral_token: refTokenParam,
      email: emailParam,
      display_name: displayNameParam,
      venmo_uri: venmoParam,
      zelle_uri: zelleParam,
      cash_uri: cashParam,
      paypal_uri: paypalParam,
      groupme: groupmeParam
    }

    setUser((prevUser) => {
      return {...prevUser, ...updatedUser}
    });
  }, [query])

  let login = (email, password) => {
    auth.signin({
        email: email,
        password: password
      }, 
      () => {
        history.replace({ pathname: "/" });
      }
    );
  };

  function register() {
    let validated = validateSave(user);
    if (!validated) {
      return;
    }
    setLoading(true);
    apiRegister.post(user).then(res => {
      if (res.ok) {
        let data = res.data;
        console.log('register successful')
        console.log('data: ', data);
        login(user.email, user.password)
      } else {
        console.log("register error:");
        console.log(res);
        toast.setMessage(`ERROR: ${res.message}`);
        toast.setShow(true);
        setLoading(false);
      }
    })
  }

  function registerRequest() {
    let validated = validateRegisterRequest(user);
    if (!validated) {
      return;
    }
    setLoading(true);

    // delete password and referral token fields
    let userRequest = {};
    Object.assign(userRequest, user);
    delete userRequest.password;
    delete userRequest.referral_token;
    // add extra values
    userRequest['phone'] = userPhone
    userRequest['channel_notes'] = userChannel

    apiRegisterRequest.post(userRequest).then(res => {
      if (res.ok) {
        let data = res.data;
        console.log('register request successful')
        console.log('data: ', data);
        toast.setMessage("Register request successful. Check your inbox in the next few days for an approval email.");
        toast.setShow(true);
        setLoading(false);
        history.push('/')
      } else {
        console.log("register request error:");
        console.log(res);
        toast.setMessage(`ERROR: ${res.message}`);
        toast.setShow(true);
        setLoading(false);
      }
    })
  }

  function validateSave(user) {
    if (!user.venmo_uri && !user.zelle_uri && !user.cash_uri && !user.paypal_uri) {
      toast.setMessage("ERROR: At least one payment URL required.");
      toast.setShow(true);
      return false;
    } else if (user.password !== secondPassword) {
      toast.setMessage("ERROR: Passwords do not match.");
      toast.setShow(true);
    } else if (!validateEmail(user.email)) {
      toast.setMessage("ERROR: Enter a valid email.");
      toast.setShow(true);
    } else {
      return true
    }
  }

  function validateRegisterRequest(user) {
    if (!user.venmo_uri && !user.zelle_uri && !user.cash_uri && !user.paypal_uri) {
      toast.setMessage("ERROR: At least one payment URL required.");
      toast.setShow(true);
      return false;
    } else if (!validateEmail(user.email)) {
      toast.setMessage("ERROR: Enter a valid email.");
      toast.setShow(true);
    } else if (!validatePhone(userPhone)) {
      toast.setMessage("ERROR: Enter a valid phone number.");
      toast.setShow(true);
    } else {
      return true
    }
  }

  function validateEmail(email) {
    if (typeof email !== "undefined") {
      var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
      if (!pattern.test(email)) {
        return false;
      } else {
        return true;
      }
    } else {
      return false
    }
  }

  function validatePhone(phone) {
    const phoneNumberRegex = /^\+?[1-9]\d{1,14}$/;
    return phoneNumberRegex.test(phone);
  }

  function validatePassword(password) {
    if (!password || password.length < 6) {
      return false;
    } else {
      return true;
    }
  }

  function disableSave() {
    if (!user.venmo_uri && !user.zelle_uri && !user.cash_uri && !user.paypal_uri) {
      return true;
    } else if (user.password !== secondPassword) {
      return true;
    } else if (!user.email || !user.referral_token)  {
      return true;
    } else if (!validateEmail(user.email)) {
      return true;
    } else if (!validatePassword(user.password)) {
      return true;
    } else {
      return false;
    }
  }

  function disableSaveRequest() {
    if (!user.venmo_uri && !user.zelle_uri && !user.cash_uri && !user.paypal_uri) {
      return true;
    } else if (!user.email)  {
      return true;
    } else if (!validateEmail(user.email)) {
      return true;
    } else {
      return false;
    }
  }
  
  return (
    <Grid container spacing={3}>

      <RulesDialog
        open={showRules}
        onClose={()=>setShowRules(false)}
      />
      <Grid item xs={12}>
          <Paper style={{padding:12}}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant={'overline'}>Credentials</Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  required
                  error={(user.email.length && !validateEmail(user.email))}
                  helperText={!validateEmail(user.email) && 'Please enter a valid email'}
                  label="Email"
                  value={user.email || ''}
                  onChange={(e)=>setUser({...user, email: e.target.value})}
                />
              </Grid>
              {/* do not show password for register requests */}
              { !props.registerRequest && (
                <Grid item xs={12}>
                  <TextField
                    required
                    error={(user.password && !validatePassword(user.password))}
                    label="Password"
                    type="password"
                    helperText={!validatePassword(user.password) && 'Password must be at least 6 characters'}
                    value={user.password || ''}
                    onChange={(e)=>setUser({...user, password: e.target.value})}
                  />
                </Grid>
              ) }
               { !props.registerRequest && (
                <Grid item xs={12}>
                  <TextField
                    required
                    error={secondPassword !== user.password}
                    label="Repeat Password"
                    type="password"
                    value={secondPassword}
                    helperText={secondPassword !== user.password && 'Passwords do not match'}
                    onChange={(e)=>setSecondPassword(e.target.value)}
                  />
                </Grid>
              ) }
              {/* do not show referral token for register requests */}
              { !props.registerRequest && (
                <React.Fragment>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant={'overline'}>Referral Token (must click invite link)</Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      required
                      label="Token"
                      disabled={true}
                      value={user.referral_token}
                      onChange={(e)=>setUser({...user, referral_token: e.target.value})}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                </React.Fragment>
              ) }
              {/* show phone number only for register request */}
              { props.registerRequest && (
                <Grid item xs={12}>
                  <TextField
                    required
                    error={(userPhone.length && !validatePhone(userPhone))}
                    helperText={!validatePhone(userPhone) && 'Please enter a valid phone number'}
                    type="tel"
                    label="Phone number"
                    value={userPhone}
                    onChange={(e)=>setUserPhone(e.target.value)}
                  />
                </Grid>
              ) }
              {/* show channel only for register request */}
              { props.registerRequest && (
                <Grid item xs={12}>
                  <TextField
                    multiline
                    label="Any other notes?"
                    helperText={"How did you hear about us?"}
                    value={userChannel}
                    onChange={(e)=>setUserChannel(e.target.value)}
                  />
                </Grid>
              ) }
              {/* <Grid item xs={12}>
                <Typography variant={'overline'}>Public Profile</Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField 
                  label="Display Name"
                  value={user.display_name || ''}
                  onChange={(e)=>setUser({...user, display_name: e.target.value})}
                />
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid> */}
              <Grid item xs={12}>
                <Typography variant={'overline'}>GroupMe</Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="GroupMe Profile"
                  value={user.groupme || ''}
                  onChange={(e)=>setUser({...user, groupme: e.target.value})}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant={'overline'}>Payment Methods (One Required)</Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Venmo Profile"
                  value={user.venmo_uri || ''}
                  onChange={(e)=>setUser({...user, venmo_uri: e.target.value})}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Zelle Profile"
                  value={user.zelle_uri || ''}
                  onChange={(e)=>setUser({...user, zelle_uri: e.target.value})}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Cash App Profile"
                  value={user.cash_uri || ''}
                  onChange={(e)=>setUser({...user, cash_uri: e.target.value})}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="PayPal Profile"
                  value={user.paypal_uri || ''}
                  onChange={(e)=>setUser({...user, paypal_uri: e.target.value})}
                />
              </Grid>
              <Grid item xs={12} md={6}>
              { loading ? (
                <CircularProgress />
              ) : (
                // register requests will not have same validation
                props.registerRequest ? (
                  <Button
                    disabled={disableSaveRequest()}
                    onClick={registerRequest}
                  >
                    Request to join
                  </Button>
                ) : (
                  <Button
                    disabled={disableSave()}
                    onClick={register}
                  >
                    Register
                  </Button>
                )
              )}
              </Grid>
            </Grid>
          </Paper>
      </Grid>
    </Grid>
  )
}

export default Register;