// core.js

import { apiProvider } from './provider';

export class ApiCore {
  constructor(options) {
    if (options.getAll) {
      this.getAll = (token) => {
        return apiProvider.getAll(options.url, token);
      };
    }

    if (options.getSingle) {
      this.getSingle = (id, token) => {
        return apiProvider.getSingle(options.url, id, token);
      };
    }

    if (options.post) {
      this.post = (model, token) => {
        return apiProvider.post(options.url, model, token);
      };
    }

    if (options.put) {
      this.put = (model, token) => {
        return apiProvider.put(options.url, model, token);
      };
    }

    if (options.patch) {
      this.patch = (model, token) => {
        return apiProvider.patch(options.url, model, token);
      };
    }

    if (options.remove) {
      this.remove = (id, token) => {
        return apiProvider.remove(options.url, id, token);
      };
    }
  }
}