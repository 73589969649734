export const SET_BET_START = "SET_BET_START";

export function setBetStart(data) {
    return dispatch => {
        dispatch({
            type: SET_BET_START,
            data: data
        });
    };
}
