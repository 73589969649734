import React from 'react';
import { Skeleton } from '@material-ui/lab';
import { Card, CardContent, CardHeader } from '@material-ui/core';

// Dispays Bet information
function BetSkeleton() { 
  return (
    <Card style={{margin:6, padding:6 }}>
      <CardHeader
        avatar={<Skeleton animation="wave" variant="circle" width={40} height={40} />}
        action={null}
        title={<Skeleton animation="wave" height={10} width="80%" style={{ marginBottom: 6 }} />}
        subheader={<Skeleton animation="wave" height={10} width="40%" />}
      />
      <Skeleton animation="wave" variant="rect" style={{height: 190}} />

      <CardContent>
        <React.Fragment>
          <Skeleton animation="wave" height={10} width="20%" />
        </React.Fragment>
      </CardContent>
    </Card>
  )
}

export default BetSkeleton;