import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { Grid, Button, TextField, Fade, Typography } from '@material-ui/core';
import apiResetPassword from '../services/api/reset-password';
import bmb_logo from '../assets/bmb_logo_512.png';
import useToast from '../Toast';

function ForgotPassword(props) {
  const history = useHistory();
  const [email, setEmail] = useState('');
  const [show, setShow] = useState(false);

  const toast = useToast();

  useEffect(() => {
    setShow(true);
  }, []);

  const resetPasswordRequest = () => {
    const model = {
      email: email
    }
    apiResetPassword.request(model).then(response => {
      console.log({response});
      if (!response.ok) {
        toast.setMessage(response.message);
        toast.setShow(true);
        setEmail('');
      } else {
        toast.setMessage("Check your email for password reset link");
        toast.setShow(true);
        history.push('/');
      }
    })
  }

  function validateEmail(email) {
    if (typeof email !== "undefined") {
      var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
      if (!pattern.test(email)) {
        return false;
      } else {
        return true;
      }
    } else {
      return false
    }
  }

  return (
    <Grid 
      container
      alignItems="center"
      justifyContent="center"
      direction="column"
      spacing={3}
    >
      <Grid item>
        <Fade in={show} {...(show ? { timeout: 500 } : {})}>
          <img style={{maxHeight: '40vh', maxWidth: '80vw', borderRadius: '10%'}} alt="BMB Logo" src={bmb_logo}/>
        </Fade>
      </Grid>
      <Grid item>
        <Fade
          in={show}
          {...(show ? { timeout: 1000 } : {})}
        >
          <Typography align="center">Enter your email to request a password reset. If you have an account you will receive an email with a password reset link valid for 10 minutes.</Typography>
        </Fade>
      </Grid>
      <Grid item>
        <Fade
          in={show}
          {...(show ? { timeout: 1500 } : {})}
        >
          <TextField
            required
            error={(email.length && !validateEmail(email))}
            helperText={!validateEmail(email) && 'Please enter a valid email'}
            label="Email"
            value={email || ''}
            onChange={(e)=>setEmail(e.target.value)}
          />
        </Fade>
      </Grid>
      <Grid item >
        <Button variant="outlined" disabled={!validateEmail(email)} onClick={resetPasswordRequest}>Request Password</Button>
      </Grid>
    </Grid>
  )
}

export default ForgotPassword;
