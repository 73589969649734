// Bet API

import { ApiCore } from './utilities/core'
import { apiProvider } from './utilities/provider';

const url = 'bets';
const plural = 'bets';
const single = 'bet';

// plural and single may be used for message logic if needed in the ApiCore class.

const apiBets = new ApiCore({
  getAll: true,
  getSingle: true,
  post: true,
  put: false,
  patch: true,
  remove: true,
  url: url,
  plural: plural,
  single: single
});


/** @param {int} user_id */ 
/** @summary Get all bets for given user */
apiBets.getBetsForUser = (user_id, token) => {
  return apiProvider.getAll(`${url}/?user_id=${user_id}`, token);
}

/** @param {int} bet_id */ 
/** @summary Settle bet */
apiBets.settleBet = (bet_id, token) => {
  return apiProvider.put(`${url}/${bet_id}/settle`, {}, token);
}

/** @param {int} bet_id */ 
/** @param {Dict} data */ 
/** @summary Update bet */
apiBets.updateBet = (bet_id, data, token) => {
  return apiProvider.put(`${url}/${bet_id}`, data, token);
}

/** @param {Array} sport_keys */ 
/** @summary Get all bets for list of given sports keys */
apiBets.getBetsForSports = (sport_keys, sort_by, show_filled, token) => {
  return apiProvider.getAll(`${url}?sport_key=${sport_keys.join()}&sort_by=${sort_by}&show_filled=${show_filled}`, token);
}

export default apiBets;