import React, { useEffect, useState } from 'react';
import {
  Paper,
  Typography,
  Tab,
  Tabs,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import BetCard from '../common/BetCard';
import { NULL_USER } from '../account/MyAccount';
import { makerDidWin } from '../common/BetCard';

import apiUsers from '../services/api/users';
import useAuth from '../Auth';
import BetSkeleton from '../common/BetSkeleton';

export function UserBets({bets}) {
  return (
    <React.Fragment>
      {bets && bets.map((bet) => (
          <BetCard
            user={bet.user}
            key={bet._id}
            id={bet._id}
            {...bet}
          />
        ))}
    </React.Fragment>
  )
}

function UnsettledBets({bets}) {
  return (
    <React.Fragment>
      {bets && bets.map((bet) => (
        <BetCard
          show_settled={true}
          user={bet.user}
          key={bet._id}
          id={bet._id}
          is_settled={bet.is_settled}
          takes={bet.takes}
          {...bet}
        />
        ))}
    </React.Fragment>
  )
}

export default function BetHistory() {
  const [user, setUser] = useState(NULL_USER);
  const [loading, setLoading] = useState(false);
  const [loadingUnsettled, setLoadingUnsettled] = useState(false);
  const [loadingClosed, setLoadingClosed] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [unsettledBets, setUnsettledBets] = useState([]);
  const [myUnsettledWinningBets, setMyUnsettledWinningBets] = useState([]);
  const [myUnsettledLosingBets, setMyUnsettledLosingBets] = useState([]);
  const [theirUnsettledWinningBets, setTheirUnsettledWinningBets] = useState([]);
  const [theirUnsettledLosingBets, setTheirUnsettledLosingBets] = useState([]);
  const [myUnsettledWinningBetAmt, setMyUnsettledWinningBetAmt] = useState(0);
  const [myUnsettledLosingBetAmt, setMyUnsettledLosingBetAmt] = useState(0);
  const [theirUnsettledWinningBetAmt, setTheirUnsettledWinningBetAmt] = useState(0);
  const [theirUnsettledLosingBetAmt, setTheirUnsettledLosingBetAmt] = useState(0);
  const [closedBets, setClosedBets] = useState([]);
  const [closedWinningBets, setClosedWinningBets] = useState([]);
  const [closedLosingBets, setClosedLosingBets] = useState([]);
  const [closedPushBets, setClosedPushBets] = useState([]);
  const [closedWinningBetAmt, setClosedWinningBetAmt] = useState(0);
  const [closedLosingBetAmt, setClosedLosingBetAmt] = useState(0);
  const [closedPushBetAmt, setClosedPushBetAmt] = useState(0);

  const auth = useAuth();

  useEffect(() => {
    _getMyUser();
    _getUnsettled();
    _getClosed();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (auth?.user?._id) {
      let myWinningBets = [];
      let myLosingBets = [];
      let theirWinningBets = [];
      let theirLosingBets = [];
      let myWinningBetAmt = 0;
      let myLosingBetAmt = 0;
      let theirWinningBetAmt = 0;
      let theirLosingBetAmt = 0;
      unsettledBets.forEach(bet => {
        if (bet.user_id === auth.user._id) {
          // My bet
          if (bet.is_settled) {
            // their unsettled
            if (makerDidWin(bet.type, bet.scores, bet.team_1, bet.team_2,
              bet.winner_team, bet.team_1_odds, bet.team_2_odds, bet.spread, bet.points_scored,
              bet.over_under) === true) {
                // they lost
                theirLosingBets.push(bet);
                theirLosingBetAmt = theirLosingBetAmt + bet.amount;
              }
            else {
                // they won 
                theirWinningBets.push(bet);
                theirWinningBetAmt = theirWinningBetAmt + bet.amount;
            }
          } else {
            // my unsettled
            if (makerDidWin(bet.type, bet.scores, bet.team_1, bet.team_2,
              bet.winner_team, bet.team_1_odds, bet.team_2_odds, bet.spread, bet.points_scored,
              bet.over_under) === true) {
                // I won
                myWinningBets.push(bet);
                myWinningBetAmt = myWinningBetAmt + bet.amount;
              }
            else {
              // I lost
              myLosingBets.push(bet);
              myLosingBetAmt = myLosingBetAmt + bet.amount;
            }
          }
        } else {
          // their bet
          if (!bet.takes[0]?.is_settled) {
            // my unsettled
            if (makerDidWin(bet.type, bet.scores, bet.team_1, bet.team_2,
              bet.winner_team, bet.team_1_odds, bet.team_2_odds, bet.spread, bet.points_scored,
              bet.over_under) === true) {
                // I lost
                myLosingBets.push(bet);
                myLosingBetAmt = myLosingBetAmt + bet.max_winning;
              }
            else {
              // I won
              myWinningBets.push(bet);
              myWinningBetAmt = myWinningBetAmt + bet.max_winning;
            }
          } else {
            // their unsettled
            if (makerDidWin(bet.type, bet.scores, bet.team_1, bet.team_2,
              bet.winner_team, bet.team_1_odds, bet.team_2_odds, bet.spread, bet.points_scored,
              bet.over_under) === true) {
                // they won
                theirWinningBets.push(bet);
                theirWinningBetAmt = theirWinningBetAmt + bet.max_winning;
              }
            else {
                // they lost 
                theirLosingBets.push(bet);
                theirLosingBetAmt = theirLosingBetAmt + bet.max_winning;
            }
          }
        }
      });

      setMyUnsettledWinningBets(myWinningBets);
      setMyUnsettledLosingBets(myLosingBets);
      setTheirUnsettledWinningBets(theirWinningBets);
      setTheirUnsettledLosingBets(theirLosingBets);
      setMyUnsettledWinningBetAmt(myWinningBetAmt.toFixed(2));
      setMyUnsettledLosingBetAmt(myLosingBetAmt.toFixed(2));
      setTheirUnsettledWinningBetAmt(theirWinningBetAmt.toFixed(2));
      setTheirUnsettledLosingBetAmt(theirLosingBetAmt.toFixed(2));
    }
  }, [unsettledBets, auth.user._id])

  useEffect(() => {
    let closedWinning = [];
    let closedLosing = [];
    let closedPush = [];
    let closedWinningAmt = 0;
    let closedLosingAmt = 0;
    let closedPushAmt = 0;

    closedBets.forEach((bet) => {
      if (bet.user_id === auth.user._id) {
        // My bet
        if (bet.maker_did_win === "push" || !bet.completed) {
          // if push or not completed, move
          closedPush.push(bet);
          closedPushAmt = closedPushAmt + bet.amount;
        } else if (bet.maker_did_win === true) {
            // I won
            // console.log({bet}, 'my bet, I won, makerDidWin');
            closedWinning.push(bet);
            closedWinningAmt = closedWinningAmt + bet.max_winning;
        } else {
            // I lost 
            // console.log({bet}, 'my bet, I lost, makerDidWin');
            closedLosing.push(bet);
            closedLosingAmt = closedLosingAmt + bet.amount;
        }
      } else {
        // their bet
        if (bet.maker_did_win === "push" || !bet.completed) {
          // if push or not completed, move
          closedPush.push(bet);
          closedPushAmt = closedPushAmt + bet.max_winning;
        } else if (bet.maker_did_win === true) {
            // I lost
            // console.log({bet}, 'their bet, I lost, makerDidWin');
            closedLosing.push(bet);
            closedLosingAmt = closedLosingAmt + bet.max_winning;
        } else {
          // I won
          // console.log({bet}, 'their bet, I won, makerDidWin');
          closedWinning.push(bet);
          closedWinningAmt = closedWinningAmt + bet.amount;
        }
      }
    })
    setClosedLosingBets(closedLosing);
    setClosedWinningBets(closedWinning);
    setClosedPushBets(closedPush);
    setClosedLosingBetAmt(closedLosingAmt.toFixed(2));
    setClosedWinningBetAmt(closedWinningAmt.toFixed(2));
    setClosedPushBetAmt(closedPushAmt.toFixed(2));

  }, [auth.user._id, closedBets])

  function _getMyUser() {
    setLoading(true);
    apiUsers.getMyUser(auth.user.token).then(res => {
      let user = res.user;
      delete user._id;
      // console.log('user: ', user);
      setUser(user);
      setLoading(false);
    })
  }

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  function _getUnsettled() {
    setLoadingUnsettled(true);
    apiUsers.getUnsettled(auth.user._id, auth.user.token).then(res => {
      let bets = res.bets;
      setUnsettledBets(bets);
      setLoadingUnsettled(false);
    })
  }

  function _getClosed() {
    setLoadingClosed(true);
    apiUsers.getClosed(auth.user._id, auth.user.token).then(res => {
      let bets = res.bets;
      console.log("closed: ", bets);
      setClosedBets(bets);
      setLoadingClosed(false);
    })
  }

  return (
    <Paper style={{padding: 12, marginBottom: 12, maxWidth: '90vw'}}>
        <Typography variant="overline">Bets</Typography>
        <Tabs 
          scrollButtons="auto"
          variant="scrollable"
          value={tabValue}
          onChange={handleTabChange}
        >
          <Tab label="Filled" />
          <Tab label="Unfilled" />
          <Tab label="Unsettled" />
          <Tab label="Historical (Closed)" />
        </Tabs>
        {tabValue === 0 && (
          <React.Fragment>
            <TableContainer component={Paper} style={{marginTop: 6}}>
              <Table aria-label="profile stats">
                <TableBody>
                  <TableRow >
                    <TableCell component="th" scope="row">
                      Filled Bets
                    </TableCell>
                    <TableCell align="right">${user.filled_bet_amount && user.filled_bet_amount.toFixed(2)}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            { loading && <BetSkeleton />}
            <UserBets bets={user.filled_bets}/>
          </React.Fragment>
        )}
        {tabValue === 1 && (
          <React.Fragment>
            <TableContainer component={Paper} style={{marginTop: 6}}>
              <Table aria-label="profile stats">
                <TableBody>
                  <TableRow >
                    <TableCell component="th" scope="row">
                      Unfilled Bets
                    </TableCell>
                    <TableCell align="right">${user.unfilled_bet_amount && user.unfilled_bet_amount.toFixed(2)}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            { loading && <BetSkeleton />}
            <UserBets bets={user.unfilled_bets}/>
          </React.Fragment>
        )}
        {tabValue === 2 && (
          <React.Fragment>
            <TableContainer component={Paper} style={{marginTop: 6}}>
              <Table aria-label="profile stats">
                <TableBody>
                  <TableRow >
                    <TableCell component="th" scope="row">
                      Unsettled Bets
                    </TableCell>
                    <TableCell align="right">${user.unsettled_bet_amount && user.unsettled_bet_amount.toFixed(2)}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            { loadingUnsettled && <BetSkeleton />}
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Bets Won that I have marked as settled (${theirUnsettledLosingBetAmt})</Typography>
              </AccordionSummary>
              <AccordionDetails style={{flexDirection: 'column'}}>
                <UnsettledBets bets={theirUnsettledLosingBets}/>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Bets Won that I have <u>not</u> yet marked as settled (${myUnsettledWinningBetAmt})</Typography>
              </AccordionSummary>
              <AccordionDetails style={{flexDirection: 'column'}}>
                <UnsettledBets bets={myUnsettledWinningBets}/>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Bets Lost that I have marked as settled (${theirUnsettledWinningBetAmt})</Typography>
              </AccordionSummary>
              <AccordionDetails style={{flexDirection: 'column'}}>
                <UnsettledBets bets={theirUnsettledWinningBets}/>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Bets Lost that I have <u>not</u> yet marked as settled (${myUnsettledLosingBetAmt})</Typography>
              </AccordionSummary>
              <AccordionDetails style={{flexDirection: 'column'}}>
                <UnsettledBets bets={myUnsettledLosingBets}/>
              </AccordionDetails>
            </Accordion>
            
            
          </React.Fragment>
        )}
        {tabValue === 3 && (
          <React.Fragment>
            <TableContainer component={Paper} style={{marginTop: 6}}>
              <Table aria-label="profile stats">
                <TableBody>
                  <TableRow >
                    <TableCell component="th" scope="row">
                      Closed Bets
                    </TableCell>
                    <TableCell align="right">${user.closed_bet_amount && user.closed_bet_amount.toFixed(2)}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            { loadingClosed && <BetSkeleton />}
            {/* <UserBets bets={closedBets}/> */}
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Won (${closedWinningBetAmt})</Typography>
              </AccordionSummary>
              <AccordionDetails style={{flexDirection: 'column'}}>
                <UserBets bets={closedWinningBets}/>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Lost (${closedLosingBetAmt})</Typography>
              </AccordionSummary>
              <AccordionDetails style={{flexDirection: 'column'}}>
                <UserBets bets={closedLosingBets}/>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Push (${closedPushBetAmt})</Typography>
              </AccordionSummary>
              <AccordionDetails style={{flexDirection: 'column'}}>
                <UserBets bets={closedPushBets}/>
              </AccordionDetails>
            </Accordion>
          </React.Fragment>
        )}
      </Paper>
  )
}