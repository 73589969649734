// icons
import SportsFootballIcon from '@material-ui/icons/SportsFootball';
import SportsHockeyIcon from '@material-ui/icons/SportsHockey';
import SportsBaseballIcon from '@material-ui/icons/SportsBaseball';
import SportsBasketballIcon from '@material-ui/icons/SportsBasketball';

export const sportsToShowExtraOdds = [
  'baseball_mlb',
  'icehockey_nhl'
]

export const sportKeys = [
  {key: 'baseball_mlb', label: 'MLB', icon: <SportsBaseballIcon/>},
  {key: 'basketball_nba', label: 'NBA', icon:  <SportsBasketballIcon/>},
  {key: 'americanfootball_nfl', label: 'NFL', icon: <SportsFootballIcon/>},
  {key: 'basketball_ncaab', label: 'NCAA Basketball', icon: <SportsBasketballIcon/>},
  {key: 'americanfootball_ncaaf', label: 'NCAA Football', icon: <SportsFootballIcon/>},
  {key: 'icehockey_nhl', label: 'NHL', icon: <SportsHockeyIcon/>},
];

export const getSport = (sportKey) => {
  // console.log("getSport ", sportKey);
  let sports = sportKeys.filter((sport) => (sport.key === sportKey));
  if (sports.length > 0) {
    // console.log(sports[0]);
    return sports[0];
  } else {
    // ret empty obj if not found
    return {};
  }
}