import React, { useEffect } from 'react';

import FilterChips from '../common/FilterChips';
import BetCard from '../common/BetCard';

import { Link } from 'react-router-dom';
import apiBets from '../services/api/bets';

import useAuth from '../Auth';

import { Button, Divider, FormControl, InputLabel, Select } from '@material-ui/core';
import { sportKeys } from '../bet/SportKeys';
import BetSkeleton from '../common/BetSkeleton';

function MarketFilters({selectedFilters, setFilters}) {
  const toggleFilter = (filter) => {
    // if filter is already selected then remove it, otherwise add it to array
    if(selectedFilters.includes(filter)) {
      console.log("remove ", filter);
      let newFilters = selectedFilters.filter(item => item !== filter);
      console.log("newFilters ", newFilters);
      setFilters(newFilters);
      console.log("selectedFilters ", selectedFilters);
    } else {
      console.log("add ", filter);
      setFilters([...selectedFilters, filter]);
      console.log("selectedFilters ", selectedFilters);
    }
  }

  return (
    <FilterChips 
      filters={sportKeys}
      selectedFilters={selectedFilters}
      toggleFilter={toggleFilter}
    />
  )
}

function SortFilter({selectedSort, setSelectedSort}) {
  const handleChange = (e) => {
    setSelectedSort(e.target.value);
  }
  return (
    <FormControl style={{margin: 6}}>
      <InputLabel htmlFor="age-native-simple">Sort By</InputLabel>
      <Select
        native
        value={selectedSort}
        onChange={handleChange}
      >
        <option value={"updated"}>Recently Updated</option>
        <option value={"starting"}>Starting Soon</option>
      </Select>
    </FormControl>
  )
}

function FilledFilter({showFilled, setShowFilled}) {
  const handleChange = (e) => {
    setShowFilled(e.target.value);
  }
  return (
    <FormControl style={{margin: 6}}>
      <InputLabel>Filled Status</InputLabel>
      <Select
        native
        value={showFilled}
        onChange={handleChange}
      >
        <option value={"all"}>All</option>
        <option value={"unfilled"}>Unfilled Only</option>
        <option value={"filled"}>Filled Only</option>    
      </Select>
    </FormControl>
  )
}


function FeedTabs(props) {
  const [bets, setBets] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [sportKeys, setSportKeys] = React.useState([]);
  const [selectedSort, setSelectedSort] = React.useState(() => {
    // getting stored value
    const saved = localStorage.getItem("selectedSort");
    console.log({saved});
    return saved || "starting";
  });
  const [showFilled, setShowFilled] = React.useState(() => {
    // getting stored value
    const saved = localStorage.getItem("showFilled");
    console.log({saved});
    return saved || "all";
  });

  let auth = useAuth();
  // console.log('feed auth: ', auth.user);

  useEffect(() => {
    _getBets();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sportKeys, selectedSort, showFilled])

  useEffect(() => {
    // storing input showFilled
    localStorage.setItem("showFilled", showFilled);
  }, [showFilled]);
  useEffect(() => {
    // storing input selectedSort
    localStorage.setItem("selectedSort", selectedSort);
  }, [selectedSort]);

  function _getBets() {
    setLoading(true);
    // console.log("_getBets token ", auth.user.token);
    apiBets.getBetsForSports(sportKeys, selectedSort, showFilled, auth.user.token).then((res) => {
      let bets = res.bets
      console.log("bets: ", bets);
      setBets(bets);
      setLoading(false);
    });
  }

  return (
    <React.Fragment>
      <MarketFilters
        selectedFilters={sportKeys}
        setFilters={setSportKeys}
       />
       <br />
       <SortFilter
          selectedSort={selectedSort}
          setSelectedSort={setSelectedSort}
       />
       <FilledFilter
          showFilled={showFilled}
          setShowFilled={setShowFilled}
       />
       <Divider/>
      { loading && (
        <BetSkeleton />
      )}      
      { (bets && bets.length) ? (
        <React.Fragment>
          {bets.map((bet) => (
            <BetCard
              user={bet.user}
              key={bet._id}
              id={bet._id}
              {...bet}
            />
          ))}
        </React.Fragment>
      ) : (
        <Button variant="outlined" component={Link} to="/new-bet">No bets found, try making your own</Button>
      )}


      {/* <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
        aria-label="Feed Tabs"
      >
        <Tab label="Market Feed" id={0}/>
        <Tab label="Activity Feed" id={1}/>
      </Tabs>
      <TabPanel value={value} index={0}>  
        { loading && (
          <CircularProgress />
        )}      
        { bets && (
          <React.Fragment>
            <MarketFilters />
            {bets.map((bet) => (
             <React.Fragment key={bet.id}>
              <div>{bet.id}</div>
              <div>{bet.name}</div>
             </React.Fragment>
            ))}
          </React.Fragment>
        )}
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ActivityFilters />
      </TabPanel> */}
    </React.Fragment>
  )
}

function HomeFeed(props) {

  return (
    <div>
      <FeedTabs />

    </div>
  )
}

export default HomeFeed;