import React, { useState, useEffect } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';

import { Link } from 'react-router-dom';

// Icons
import AccountCircle from '@material-ui/icons/AccountCircle';
import HomeIcon from '@material-ui/icons/Home';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import NotificationsIcon from '@material-ui/icons/Notifications';
import HistoryIcon from '@material-ui/icons/History';
import GavelIcon from '@material-ui/icons/Gavel';
import AddIcon from '@material-ui/icons/Add';
import useAuth from '../Auth';
import { Fade, Typography } from '@material-ui/core';
import { RulesDialog } from '../account/Register';

// const useStyles = makeStyles((theme) => ({
//   primary: {
//     color: theme.palette.getContrastText(theme.palette.primary.main),
//     // backgroundColor: theme.palette.primary.main,
//   },
// }));

function Home(props) {
  const [greeting, setGreeting] = useState("Welcome");
  const [show, setShow] = useState(false);
  const [showRules, setShowRules] = useState(false);

  // const classes = useStyles();
  let auth = useAuth();

  useEffect(() => {
    setShow(true);
    const myDate = new Date();
    const currentHour = myDate.getHours();
    if (currentHour < 12) {
      setGreeting('Good Morning');
    } else if (currentHour >= 12 && currentHour <= 17) {
      setGreeting('Good Afternoon');
    } else if (currentHour >= 17 && currentHour <= 24) {
      setGreeting('Good Evening');
    }
  }, [greeting, auth])

  return (
    <React.Fragment>
      <RulesDialog
        open={showRules}
        disabled={true}
        onClose={()=>setShowRules(false)}
      />
      <Fade in={show} {...(show ? { timeout: 1000 } : {})}>
        <Typography variant="h4">
          {greeting}, {auth.user.display_name}
        </Typography>
      </Fade>
      <List>
        <Fade in={show} {...(show ? { timeout: 1250 } : {})}>
          <ListItem button component={Link} to="/feed">
            <ListItemAvatar>
              <Avatar>
                <HomeIcon color="primary"/>
                </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Bet Feed" secondary="See open bets from all users" />
          </ListItem>
        </Fade>
        <Fade in={show} {...(show ? { timeout: 1500 } : {})}>
          <ListItem button component={Link} to="/chats">
            <ListItemAvatar>
              <Avatar>
                <ChatBubbleIcon color="primary"/>
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Chats" secondary="Real-time chat for all users" />
          </ListItem>
        </Fade>
        <Fade in={show} {...(show ? { timeout: 1750 } : {})}>
          <ListItem button component={Link} to="/new-bet">
            <ListItemAvatar>
              <Avatar>
                <AddIcon color="primary"/>
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="New Bet" secondary="Place a new bet" />
          </ListItem>
        </Fade>
        <Fade in={show} {...(show ? { timeout: 2000 } : {})}>
          <ListItem button component={Link} to="/notifications">
            <ListItemAvatar>
              <Avatar>
                <NotificationsIcon color="primary" />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Notifications" secondary="See notifications on your bets/takes" />
          </ListItem>
        </Fade>
        <Fade in={show} {...(show ? { timeout: 2250 } : {})}>
          <ListItem button component={Link} to="/bet-history">
            <ListItemAvatar>
              <Avatar>
                <HistoryIcon color="primary"/>
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Bet History" secondary="View and manage your open/closed bets" />
          </ListItem>
        </Fade>
        <Fade in={show} {...(show ? { timeout: 2500 } : {})}>
          <ListItem button component={Link} to="/account">
            <ListItemAvatar>
              <Avatar>
                <AccountCircle color="primary"/>
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Account" secondary="Update your account and profile" />
          </ListItem>
        </Fade>
        <Fade in={show} {...(show ? { timeout: 2750 } : {})}>
          <ListItem button onClick={()=>setShowRules(true)}>
            <ListItemAvatar>
              <Avatar>
                <GavelIcon color="primary"/>
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Rules" secondary="View app rules & instructions" />
          </ListItem>
        </Fade>
      </List>
    </React.Fragment>
  )
}

export default Home;