import React, {useState, useEffect} from 'react';
import moment from 'moment';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Chip from '@material-ui/core/Chip';
import Typography from '@material-ui/core/Typography';

// for table
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import MuiTableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { getSport, sportsToShowExtraOdds } from '../bet/SportKeys';
import { TeamAvatar } from '../common/BetCard';
import { withStyles } from '@material-ui/styles';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  buttonCell: {
    background: theme.palette.primary.main,
    '&:hover': {
      background: theme.palette.primary.light
    },
    border: "2px solid white",
    borderRadius: "12px",
    color: theme.palette.primary.contrastText,
    // make unselectable
    // -webkit-user-select: none; /* Safari */        
    // -moz-user-select: none; /* Firefox */
    // -ms-user-select: none; /* IE10+/Edge */
    userSelect: "none", /* Standard */
  },
  grayCell: {
    background: "#7d7c7c",
    border: "2px solid white",
    borderRadius: "12px",
    color: theme.palette.primary.contrastText,
    userSelect: "none",
  },
  blackCell: {
    background: theme.palette.primary.main,
    border: "2px solid white",
    borderRadius: "12px",
    color: theme.palette.primary.contrastText,
    userSelect: "none", /* Standard */
  }
}))

function EventCard({event, selectEvent, displayOnly, newBet, sport_key, home_team, away_team, commence_time, bookmakers}) {
  const [siteName, setSiteName] = useState("");
  const [h2hOutcomes, setH2hOutcomes] = useState([]);
  const [spreadsOutcomes, setSpreadsOutcomes] = useState([]);
  const [totalsOutcomes, setTotalsOutcomes] = useState([]);

  const [sportObj, setSportObj] = useState({});


  useEffect(() => {
    function setSite(sites) {
      // console.log("sites: ", sites);
      // get William Hill if available
      if (sites) {
        sites.forEach(site => {
          if (site.key === "williamhill_us") {
            setSiteName(site.title);

            let h2hOdds = getOutcomes(site.markets, "h2h");
            let spreadsOdds = getOutcomes(site.markets, "spreads");
            let totalsOdds = getOutcomes(site.markets, "totals");

            console.log("site: ", site);
            
            setH2hOutcomes(h2hOdds);
            setSpreadsOutcomes(spreadsOdds);
            setTotalsOutcomes(totalsOdds);

            // console.log("h2hOutcomes: ", h2hOutcomes);
            // console.log("spreadsOutcomes: ", spreadsOutcomes);
            // console.log("totalsOutcomes: ", totalsOutcomes);
          }
        });
        // otherwise default to first site
        if (siteName === "" && sites[0]) {
          let site = sites[0]
          setSiteName(site.title);

          let h2hOdds = getOutcomes(site.markets, "h2h");
          let spreadsOdds = getOutcomes(site.markets, "spreads");
          let totalsOdds = getOutcomes(site.markets, "totals");
            
          setH2hOutcomes(h2hOdds);
          setSpreadsOutcomes(spreadsOdds);
          setTotalsOutcomes(totalsOdds);

          console.log("default site: ", site);
        }
      }
    }

    // update selected site when new sites come in
    setSite(bookmakers);
    // update sport obj when new props
    setSportObj(getSport(sport_key));
  }, [bookmakers, siteName, sport_key])

  function getOutcomes(markets, key) {
    var outcomes = [];
    markets.forEach(market => {
      if (market.key === key) {
        // console.log(market);
        outcomes =  market.outcomes
      }
    })
    return outcomes;
  }

  return (
    <Card style={{marginBottom: 6}}>
      {/* Bet header */}
      <CardHeader
        style={{padding: 6}}
        // title={away_team + " @ " + home_team}
        subheader={moment(Date.parse(commence_time)).local().format('L, LT')}
      />
      <CardContent
        style={{padding: 6}}
      >
        {/* Team Logos */}
        {/* <Grid item container sm={2} xs={12} spacing={1} style={{paddingBottom:6}}>
          <Grid item xs={6}>
            <TeamAvatar
                variant="rounded"
                component={Paper}
                elevation={2}
                alt={away_team}
                src={`/sports/teams/${away_team}.png`}
              >
                {away_team.length > 0 && (away_team.charAt(0))}
            </TeamAvatar>
          </Grid>
          <Grid item xs={6}>
            <TeamAvatar
                variant="rounded"
                component={Paper}
                elevation={2}
                alt={home_team}
                src={`/sports/teams/${home_team}.png`}
              >
                {home_team.length > 0 && (home_team.charAt(0))}
            </TeamAvatar>
          </Grid>
        </Grid> */}
        {/* Tags */}
        { sportObj && (
          <Chip 
            label={sportObj.label}
            icon={sportObj.icon}
          />
        )}
        {/*Odds */}
        {/* <OddsGrid 
          displayOnly={displayOnly}
          event={event}
          selectEvent={selectEvent}
          homeTeam={home_team}
          awayTeam={away_team}
          site={siteName}
          h2hOutcomes={h2hOutcomes}
          spreadsOutcomes={spreadsOutcomes}
          totalsOutcomes={totalsOutcomes}
        /> */}
        <OddsTable
          displayOnly={displayOnly}
          event={event}
          selectEvent={selectEvent}
          homeTeam={home_team}
          awayTeam={away_team}
          site={siteName}
          h2hOutcomes={h2hOutcomes}
          spreadsOutcomes={spreadsOutcomes}
          totalsOutcomes={totalsOutcomes}
          newBet={newBet}
        />
      </CardContent>
    </Card>
  )
}

function getAmericanOdds(decimalOdds) {
  if (decimalOdds >= 2.0) {
    return Math.round((decimalOdds-1)*100);
  } else if (decimalOdds > 1.0) {
    return Math.round((-100)/(decimalOdds-1));
  } else {
    return '-'
  }
}

const TableCell = withStyles({
  root: {
    borderBottom: "none",
  }
})(MuiTableCell);

function OddsTable({event, selectEvent, displayOnly, homeTeam, awayTeam, site, h2hOutcomes, spreadsOutcomes, totalsOutcomes, newBet}) {

  const classes = useStyles();

  const getOdds = (name, outcomes) => {
    console.log("getOdds: ", name, outcomes)
    let odds = 0;
    outcomes.map(outcome => (
      outcome.name === name ? odds = outcome.price : null
      ))
    return getAmericanOdds(odds);
  }

  const getPoints = (name, outcomes) => {
    console.log("getPoints: ", name, outcomes)
    let points = 0;
    outcomes.map(outcome => (
      outcome.name === name ? points = outcome.point : null
      ))
    return points;
  }

  const openMoneyline = (team_1_odds, team_2_odds, selected_team) => {
    if (!displayOnly) {
      selectEvent(event, "Moneyline", team_1_odds, team_2_odds, selected_team)
    }
  }

  const openSpread = (sport_key, team_1_odds, team_2_odds, selected_team, spread) => {
    if (!displayOnly) {
      if (sportsToShowExtraOdds.includes(sport_key)) {
        selectEvent(event, "Spread", team_1_odds, team_2_odds, selected_team, spread)
      } else {
        // default to 100 (even odds) for sports without extra odds
        selectEvent(event, "Spread", 100, -100, selected_team, spread)
      }
      
    }
  }

  const openTotals = (sport_key, team_1_odds, team_2_odds, over_under, points_scored) => {
    if (!displayOnly) {
      // placeholders for selected_team and spread
      if (sportsToShowExtraOdds.includes(sport_key)) {
        selectEvent(event, "Totals", team_1_odds, team_2_odds, '', 1, over_under, points_scored)
      } else {
        // default to 100 (even odds) for sports without extra odds
        selectEvent(event, "Totals", 100, -100, '', 1, over_under, points_scored)
      }
    }
  }

  return (
    <div >
    <TableContainer 
      style={{overflow: 'hidden'}}
      >
      <Table size="small" padding="checkbox">
        <TableHead>
          <TableRow>
            <TableCell align="center"><Typography variant="overline">Team</Typography></TableCell>
            <TableCell align="center"><Typography variant="overline">M/L</Typography></TableCell>
            <TableCell align="center"><Typography variant="overline">Spreads</Typography></TableCell>
            <TableCell align="center"><Typography variant="overline">Totals</Typography></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {/* away team */}
          <TableRow>
            <TableCell align="center">
              {awayTeam}
              <TeamAvatar
                variant="rounded"
                style={{marginLeft: "auto", marginRight: "auto"}}
                alt={awayTeam}
                src={`/sports/teams/${awayTeam}.png`}
              >
                {awayTeam.length > 0 && (awayTeam.charAt(0))}
            </TeamAvatar>
            </TableCell>
            <TableCell align="center" className={newBet?.winner_team === awayTeam && newBet?.type === "Moneyline" ? classes.blackCell : (displayOnly ? classes.grayCell : classes.buttonCell)} onClick={()=>openMoneyline(getOdds(homeTeam, h2hOutcomes), getOdds(awayTeam, h2hOutcomes), awayTeam)}>
              {getOdds(awayTeam, h2hOutcomes)}
            </TableCell>
            <TableCell align="center" className={newBet?.winner_team === awayTeam && newBet?.type === "Spread" ? classes.blackCell : (displayOnly ? classes.grayCell : classes.buttonCell)} onClick={()=>openSpread(event.sport_key, getOdds(homeTeam, spreadsOutcomes), getOdds(awayTeam, spreadsOutcomes), awayTeam, -getPoints(awayTeam, spreadsOutcomes))}>
              {/* {getOdds(awayTeam, spreadsOutcomes)}  */}
              {event && sportsToShowExtraOdds.includes(event.sport_key) ? getOdds(awayTeam, spreadsOutcomes) : null} 
              ({getPoints(awayTeam, spreadsOutcomes)})
            </TableCell>
            <TableCell align="center" className={newBet?.over_under === 'over' && newBet?.type === "Totals" ? classes.blackCell : (displayOnly ? classes.grayCell : classes.buttonCell)} onClick={()=>openTotals(event.sport_key, getOdds("Over", totalsOutcomes), getOdds("Under", totalsOutcomes), "over", getPoints("Over", totalsOutcomes))}>
                {/* {getOdds("Over", totalsOutcomes)}  */}
                {event && sportsToShowExtraOdds.includes(event.sport_key) ? getOdds("Over", totalsOutcomes) : null} 
                (O {getPoints("Over", totalsOutcomes)})
            </TableCell>
          </TableRow>
          {/* home team */}
          <TableRow>
            <TableCell align="center">
              {homeTeam}
              <TeamAvatar
                variant="rounded"
                style={{marginLeft: "auto", marginRight: "auto"}}
                alt={homeTeam}
                src={`/sports/teams/${homeTeam}.png`}
              >
                {homeTeam.length > 0 && (homeTeam.charAt(0))}
            </TeamAvatar>
            </TableCell>
            <TableCell align="center" className={newBet?.winner_team === homeTeam && newBet?.type === "Moneyline" ? classes.blackCell : (displayOnly ? classes.grayCell : classes.buttonCell)} onClick={()=>openMoneyline(getOdds(homeTeam, h2hOutcomes), getOdds(awayTeam, h2hOutcomes), homeTeam)}>
                {getOdds(homeTeam, h2hOutcomes)}
            </TableCell>
            <TableCell align="center" className={newBet?.winner_team === homeTeam && newBet?.type === "Spread" ? classes.blackCell : (displayOnly ? classes.grayCell : classes.buttonCell)} onClick={()=>openSpread(event.sport_key, getOdds(homeTeam, spreadsOutcomes), getOdds(awayTeam, spreadsOutcomes), homeTeam, getPoints(homeTeam, spreadsOutcomes))}>
                {/* {getOdds(homeTeam, spreadsOutcomes)}  */}
                {event && sportsToShowExtraOdds.includes(event.sport_key) ? getOdds(homeTeam, spreadsOutcomes) : null} 
                ({getPoints(homeTeam, spreadsOutcomes)})
            </TableCell>
            {/* over/under odds switched so the odds seen reflect the under position */}
            <TableCell align="center" className={newBet?.over_under === 'under' && newBet?.type === "Totals" ? classes.blackCell : (displayOnly ? classes.grayCell : classes.buttonCell)} onClick={()=>openTotals(event.sport_key, getOdds("Under", totalsOutcomes), getOdds("Over", totalsOutcomes), "under", getPoints("Under", totalsOutcomes))}>
              {/* {getOdds("Under", totalsOutcomes)}  */}
              {event && sportsToShowExtraOdds.includes(event.sport_key) ? getOdds("Under", totalsOutcomes) : null} 
              (U {getPoints("Under", totalsOutcomes)})
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
    </div>
  )

}


export default EventCard;