import React, { Component } from "react";
// import PropTypes from "prop-types";
import { authContext } from "../Auth";

import moment from 'moment';
import { Link } from 'react-router-dom';
import { ListItemAvatar, ListItem, ListItemText, Avatar } from '@material-ui/core';

class MessageBubble extends Component {
  static contextType = authContext;

  constructor(props) {
    super(props);

    this.state = {
      hasMedia: this.props.message.type === "media",
      mediaDownloadFailed: false,
      mediaUrl: null,
      participant: null
    };
  }

  componentDidMount = async () => {
    this.setState({
      ...this.state,
      participant: await this.props.message.getParticipant(),
      type: (await this.props.message.getParticipant()).type
    });
    if (this.state.hasMedia) {
      this.props.message.media
        .getContentTemporaryUrl()
        .then((url) => {
          this.setState({ mediaUrl: url });
        })
        .catch((e) => this.setState({ mediaDownloadFailed: true }));
    }
    document
      .getElementById(this.props.message.sid)
      .scrollIntoView({ behavior: "smooth" });
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    document
      .getElementById(this.props.message.sid)
      .scrollIntoView({ behavior: "smooth" });
  }

  render = () => {
    const m = this.props.message;
    // const type = this.state.type;

    console.log("participant: ", this.state.participant);
    if (this.state.participant) {
      console.log("participant.attributes: ", this.state.participant.attributes);
    }
    

    if (this.state.hasMedia) {
      console.log("Message is media message");
      // log media properties
      console.log("Media properties", m.media);
    }
    console.log(m);

    // show this users message on right, other user messages on left
    if (this.state.participant) {
      if ( this.props.direction === "outgoing") {
        return (
          <ListItem id={m.sid}>
            <ListItemText 
              align="right"
              primary={m.body}
              primaryTypographyProps={{ style: { wordBreak: "break-word", maxWidth: '60vw'} }}
              secondary={this.state.participant.attributes.display_name + ' | ' + moment.utc(m.state.timestamp).local().format('L, LT')}
            />
            <ListItemAvatar align="right">
              <Avatar alt={this.state.participant.attributes.display_name} src={this.state.participant.attributes.picture} />
            </ListItemAvatar>
          </ListItem>
        )
      } else {
        return (
          <ListItem id={m.sid}>
            <ListItemAvatar>
              <Avatar 
                to={m.author ? `/user/${m.author}` : '/account'}
                alt={this.state.participant.attributes.display_name}
                src={this.state.participant.attributes.picture}
                component={Link}
              />
            </ListItemAvatar>
            <ListItemText 
              align="left"
              primary={m.body}
              primaryTypographyProps={{ style: { wordBreak: "break-word", maxWidth: '60vw'} }}
              secondary={(this.state.participant ? this.state.participant.attributes.display_name : "N/A") + ' | ' + moment.utc(m.state.timestamp).local().format('L, LT')}
            />
            {/* <div>
              {this.state.hasMedia && (
                <Media
                  hasFailed={this.state.mediaDownloadFailed}
                  url={this.state.mediaUrl}
                />
              )}
            </div> */}
          </ListItem>
        )
      } 
    } else {
      return "";
    }
  };
}

// class Media extends PureComponent {
//   render = () => {
//     const { hasFailed, url } = this.props;
//     return (
//       <div
//         className={`${styles.media}${!url ? " " + styles.placeholder : ""}`}
//         onClick={() => {
//           Modal.info({
//             centered: true,
//             icon: null,
//             okText: "Close",
//             width: "60%",
//             content: (
//               <div className={styles.picture_container}>
//                 <img style={{ width: "100%", height: "100%" }} src={url} />
//               </div>
//             )
//           });
//         }}
//       >
//         {!url && !hasFailed && <Spin />}

//         {hasFailed && (
//           <div style={{ display: "flex", flexDirection: "column" }}>
//             <Icon type={"warning"} style={{ fontSize: "5em" }} />
//             <p>Failed to load media</p>
//           </div>
//         )}

//         {!hasFailed && url && (
//           <div className={styles.media_icon}>
//             <div style={{ zIndex: 123, position: "absolute" }}>
//               <Icon type={"eye"} style={{ fontSize: "5em", opacity: 0.3 }} />
//             </div>
//             <div
//               className={styles.picture_preview}
//               style={{ backgroundImage: `url(${url})`, zIndex: 122 }}
//             ></div>
//           </div>
//         )}
//       </div>
//     );
//   };
// }

// Media.propTypes = {
//   hasFailed: PropTypes.bool.isRequired,
//   url: PropTypes.string
// };

export default MessageBubble;
