import React, { useState, useEffect, useRef } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useHistory,
  useLocation,
  Link
} from "react-router-dom";
import { connect } from "react-redux";

import { Client as ConversationsClient } from "@twilio/conversations";
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';

import AppBarContainer from './common/AppBarContainer';
import apiNotifications from './services/api/notifications';
import apiUsers from './services/api/users';
import apiChats from "./services/api/chats";

import bmb_logo from './assets/bmb_logo_512.png';
import useAuth from "./Auth";

// Main Routes
import Home from './home/Home';
import HomeFeed from './home/HomeFeed';
import Chats from './chats/Chats';
import NewBet from './newBet/NewBet';
import Bet from './bet/Bet';
import EditBet from './bet/EditBet';
import Notifications from './notifications/Notifications';
import Account from './account/MyAccount';
import PublicProfile from "./account/PublicProfile";
import Register from "./account/Register";
import Settle from "./bet/Settle";
import BetHistory from "./betHistory/BetHistory";
import ResetPassword from "./resetPassword/ResetPassword";
import ForgotPassword from "./resetPassword/ForgotPassword";
import { 
  Fade,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from "@material-ui/core";

import ConversationsApp from "./chats/ConversationsApp";
import ChatList from "./chats/ChatList";
import { setNotifications } from "./redux/actions/NotificationActions";
import useConversations from "./Conversations";


function AuthRouter(props) {
    const [showUnpaidDialog, setShowUnpaidDialog] = useState(false);
    const [loadingNotifications, setLoadingNotifications] = useState(false);
    const conversationsClient = useRef();
    const { setNotifications } = props;
    
    const {
      setConversations,
      conversations,
      setConversationStatus,
      checkHasUnreadMessage,
      currConvoSidRef,
      setHasUnreadMessages
    } = useConversations();
  
    let auth = useAuth();

    useEffect(() => {
      if (auth.user) {
        checkHasUnreadMessage();
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auth, conversations])

    useEffect(() => {
      function checkUnpaid() {
        // check unpaid/unsettled bets when auth state changes
        if (auth.user) {
          apiUsers.getMyUser(auth.user.token).then(res => {
            let user = res.user;
            console.log('app user: ', user);
            if (user.has_unsettled_bets) {
              setShowUnpaidDialog(true);
            }
          })
        }
      }
      if (auth.user) {
        checkUnpaid();
      }
    }, [auth, setShowUnpaidDialog])
  
    useEffect(() => {
      function getNotifications() {
        if (auth.user) {
          setLoadingNotifications(true);
          apiNotifications.getAll(auth.user.token).then(res => {
            let notifications = res.notifications;
            setNotifications(notifications);
            setLoadingNotifications(false);
          })
        }
      }
      
      getNotifications();
      const intervalId = setInterval(() => {
        getNotifications();
      }, 30000);
      return () => clearInterval(intervalId);
    }, [auth, setNotifications])  

    useEffect(() => {
      function getJoinConversations() {
        if(auth.user) {
          console.log('getJoinConversations()')
          const initConversations = (newToken) => {
            conversationsClient.current = new ConversationsClient(newToken);
            conversationsClient.current.on("connectionStateChanged", (state) => {
              if (state === "connecting") {
                setConversationStatus("default");
              }
              if (state === "connected") {
                setConversationStatus("success");
              }
              if (state === "disconnecting") {
                setConversationStatus("default");
              }
              if (state === "disconnected") {
                setConversationStatus("warning");
              }
              if (state === "denied") {
                setConversationStatus("error");
              }
            });
        
            conversationsClient.current.on("conversationJoined", (conversation) => {
              console.log("joined conversation:");
              console.log({conversation});
              setConversations(prevConversations => {
                console.log({prevConversations});
                if (prevConversations.find((convo) => convo.sid === conversation.sid)) {
                  console.log("already added conversation");
                  return [...prevConversations]
                } else {
                  return [...prevConversations, conversation];
                }
              });
            });
      
            conversationsClient.current.on("conversationLeft", async (thisConversation) => {
              // console.log({conversations});
              setConversations(prevConversations => [...prevConversations.filter((it) => it !== thisConversation)]);
            });
          };
      
          const getToken = () => {
            // Paste your unique Chat token function
            console.log("getting chat token");
            console.log({auth});
            apiChats.getToken(auth.user.token).then(data => {
              console.log({data});
              const myToken = data.token;
              initConversations(myToken);
            })
          };
      
          getToken();
        }
      }
      if (auth.user) {
        getJoinConversations();
      }
    }, [auth, setConversations, setConversationStatus])


    useEffect(() => {
      const convoDidUpdate = (data) => {
        if (data.updateReasons.includes('lastMessage')) {
          // checkHasUnreadMessage();
          console.log(data.conversation, 'data.conversation');
          if (currConvoSidRef.current && data.conversation.sid === currConvoSidRef.current) {
              console.log(`${data.conversation.sid} curr convo ${currConvoSidRef.current}`)
              // mark read
              data.conversation.updateLastReadMessageIndex(data.conversation.lastMessage.index);
          } else {
              console.log(`${data.conversation.sid} not curr convo ${currConvoSidRef.current}, must be unread`);
              // setHasUnreadMessageLocal(true);
              setHasUnreadMessages(true);
          } 
        }
      }

      conversations.forEach(conversation => {
        console.log({conversation}, 'auth router effect');
        if (conversation._events.updated.length > 1) {
          console.log('already added update handler')
        } else {
          console.log('adding update handler')
          conversation.on('updated', convoDidUpdate);
        }
        
      })
    }, [conversations, currConvoSidRef, setHasUnreadMessages])
  
    function handleClose() {
      setShowUnpaidDialog(false);
    }
  
    return (
      <Router>
        <Dialog onClose={handleClose} open={showUnpaidDialog}>
          <DialogTitle>
            Settle Your Bets
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              You have unsettled bets. Please go to your bet history and settle all unsettled bets.
            </DialogContentText>
            <DialogActions>
              <Button onClick={handleClose}>Okay</Button>
            </DialogActions>
          </DialogContent>
        </Dialog>
        <AppBarContainer>
          <Switch>
            <Route path="/login">
              <LoginPage />
            </Route>
            <RegisterReroute path="/forgot-password">
              <ForgotPassword />
            </RegisterReroute>
            <RegisterReroute path="/reset-password">
              <ResetPassword />
            </RegisterReroute>
            <RegisterReroute path="/register">
              <Register />
            </RegisterReroute>
            <RegisterReroute path="/register-request">
              <Register registerRequest={true} />
            </RegisterReroute>
            <PrivateRoute path="/account">
              <Account />
            </PrivateRoute>
            <PrivateRoute path="/bet-history">
              <BetHistory />
            </PrivateRoute>
            <PrivateRoute path="/user/:userId">
              <PublicProfile />
            </PrivateRoute>
            <PrivateRoute path="/notifications">
              <Notifications
                loadingNotifications={loadingNotifications}
              />
            </PrivateRoute>
            <PrivateRoute path="/new-bet">
              <NewBet />
            </PrivateRoute>
            <PrivateRoute path='/chats'>
              <ChatList />
            </PrivateRoute>
            <PrivateRoute path="/forum">
              <Chats />
            </PrivateRoute>
            <PrivateRoute path="/conversations/:conversationId">
              <ConversationsApp
              />
            </PrivateRoute>
            <PrivateRoute path="/conversations">
              <ConversationsApp
              />
            </PrivateRoute>
            <PrivateRoute exact path="/bet/:betId">
              <Bet />
            </PrivateRoute>
            <PrivateRoute exact path="/bet/:betId/edit">
              <EditBet />
            </PrivateRoute>
            <PrivateRoute path="/settle/:betId">
              <Settle />
            </PrivateRoute>
            <PrivateRoute path="/feed">
              <HomeFeed />
            </PrivateRoute>
            <PrivateRoute path="/">
              <Home />
            </PrivateRoute>
          </Switch>
        </AppBarContainer>
      </Router>
    )
  
  }
  
  // A wrapper for <Route> that redirects to the login
  // screen if you're not yet authenticated.
  function PrivateRoute({ children, ...rest }) {
    let auth = useAuth();
    return (
      <Route
        {...rest}
        render={({ location }) =>
          auth.user ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: location }
              }}
            />
          )
        }
      />
    );
  }
  
  // A wrapper for <Route> that redirects to the account screen if logged in
  function RegisterReroute({ children, ...rest }) {
    let auth = useAuth();
    return (
      <Route
        {...rest}
        render={({ location }) =>
          !auth.user ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: "/account",
                state: { from: location }
              }}
            />
          )
        }
      />
    );
  }
  
  function LoginPage() {
    let history = useHistory();
    let location = useLocation();
    let auth = useAuth();
  
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
  
    const [show, setShow] = useState(false);
  
    useEffect(() => {
      setShow(true);
    }, []);
  
    let { from } = location.state || { from: { pathname: "/" } };
    let login = () => {
      auth.signin({
          email: email,
          password: password
        }, 
        () => {
          history.replace(from);
        }
      );
    };
  
    // redirect if auth comes in while on screen
    useEffect(() => {
      console.log("useEffect on LoginPage triggered")
      if (auth.user) {
        history.replace(from)
      }
    }, [auth, from, history])
  
    return (
      <Grid 
        container
        alignItems="center"
        justifyContent="center"
        direction="column"
        spacing={3}
      >
        <Grid item>
          <Fade in={show} {...(show ? { timeout: 500 } : {})}>
            <img style={{maxHeight: '40vh', maxWidth: '80vw', borderRadius: '10%'}} alt="BMB Logo" src={bmb_logo}/>
          </Fade>
        </Grid>
        <Grid item>
          <Fade
            in={show}
            {...(show ? { timeout: 1000 } : {})}
          >
            <TextField
              variant="outlined"
              disabled={auth.isAuthenticating || false}
              label="Email"
              type="email"
              InputLabelProps={{
                shrink: true,
              }}
              value={email}
              onChange={(e)=> { setEmail(e.target.value); auth.setErrorMessage(null); }}
            />
          </Fade>
        </Grid>
        <Grid item>
          <Fade
            in={show}
            {...(show ? { timeout: 1500 } : {})}
          >
          <TextField
            variant="outlined"
            disabled={auth.isAuthenticating || false}
            label="Password"
            type="password"
            InputLabelProps={{
              shrink: true,
            }}
            value={password}
            onChange={(e)=>{ setPassword(e.target.value); auth.setErrorMessage(null); }}
          />
          </Fade>
          </Grid>
          {
          auth.errorMessage && <Grid item style={{padding: 0}}>
            <p style={{lineHeight: 0, color: 'red'}}>{auth.errorMessage}</p>
          </Grid>
          }
          <Grid item >
            { auth && auth.isAuthenticating ? (
              <CircularProgress />
            ) : (
              <Fade in={!auth.isAuthenticating} {...(show ? { timeout: 2000 } : {})}>
                <Button variant="outlined" disabled={email.length < 2 || password.length < 6} onClick={login}>Log in</Button>
              </Fade>
            )}
          </Grid>
          <Grid item>
            <Typography>
              <Link to="/forgot-password">forgot password?</Link>
            </Typography>
          </Grid>
          <Grid item>
            <Typography>
              or <Link to="/register-request">request to join</Link>
            </Typography>
          </Grid>
        </Grid>
    );
  }

  const mapStateToProps = state => ({});

  export default connect(mapStateToProps, { setNotifications })(AuthRouter);
