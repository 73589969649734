import React, { useState, useEffect } from 'react';
import {
  useParams
} from "react-router-dom";
import { 
  CircularProgress,
  Button,
  Card,
  CardContent,
  CardActions,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from '@material-ui/core';

import useAuth from '../Auth';
import { BetDetails } from '../common/BetCard';
import { UserCardHeader } from '../account/MyAccount';

import apiBets from '../services/api/bets';
import apiTakes from '../services/api/takes';
import useToast from '../Toast';
import TakeCard from './TakeCard';

const Settle = () => {
  const [bet, setBet] = useState({});
  const [takes, setTakes] = useState([]);
  const [showMarkDialog, setShowMarkDialog] = useState(false);
  const [showMarkTakeDialog, setShowMarkTakeDialog] = useState(false);
  const [isBettor, setIsBettor] = useState(false);
  const [myTake, setMyTake] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingTakes, setLoadingTakes] = useState(true);

  let { betId } = useParams();

  let auth = useAuth();
  const toast = useToast();

  console.log("betId: ", betId);

  useEffect(() => {
    _getBet(betId);
    _getBetTakes(betId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [betId])

  function _getBet(betId) {
    setLoading(true);
    apiBets.getSingle(betId, auth.user.token).then(res => {
      let bet = res.bet;
      console.log('bet: ', bet)
      if (bet.user_id === auth.user._id) {
        setIsBettor(true);
        console.log('is my bet');
      }
      setBet(bet);
      setLoading(false);
    });
  }

  function _getBetTakes(betId) {
    setLoadingTakes(true);
    apiTakes.getAllForBet(betId, auth.user.token).then(res => {
      let takes = res.takes;
      takes.map(take => (
        take.user_id === auth.user._id ? setMyTake(take) : null
      ))
      setTakes(takes);
      setLoadingTakes(false);
    });
  }


  function settleBet(takeId) {
    setLoading(true);
    apiBets.settleBet(betId, auth.user.token).then(res => {
      console.log("res: ", res);
      setLoading(false);
      toast.setMessage('Bet marked as settled')
      toast.setShow(true);
      _getBet(betId);
      _getBetTakes(betId);
      setShowMarkDialog(false);
    }).catch(err => {
      console.log(err);
      setShowMarkDialog(false);
    });
  }

  function settleTake(takeId) {
    setLoading(true);
    apiTakes.settleTake(takeId, auth.user.token).then(res => {
      console.log("res: ", res);
      setLoading(false);
      toast.setMessage('Bet marked as settled')
      toast.setShow(true);
      _getBet(betId);
      _getBetTakes(betId);
      setShowMarkTakeDialog(false);
    }).catch(err => {
      console.log(err);
      setShowMarkTakeDialog(false);
    });
  }

  const MarkAsSettleDialog = (props) => {
    return (
      <Dialog
        maxWidth={'md'}
        open={props.open}
        onClose={props.handleClose}
      >
        <DialogTitle>Mark As Settled?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to mark as settled?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={props.onClose}
            variant="outlined"
          >
            No, do not mark as settled.
          </Button>
          <Button 
            onClick={() => props.settleBet(props.bet?._id)} 
            color="primary"
            variant="contained"
          >
              Mark as settled.
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  const MarkAsSettleTakeDialog = (props) => {
    return (
      <Dialog
        maxWidth={'md'}
        open={props.open}
        onClose={props.handleClose}
      >
        <DialogTitle>Mark As Settled?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to mark as settled?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={props.onClose}
            variant="outlined"
          >
            No, do not mark as settled.
          </Button>
          <Button 
            onClick={() => props.settleTake(props.take?._id)} 
            color="primary"
            variant="contained"
          >
              Mark as settled.
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  return (
    <React.Fragment>
    {(loading || loadingTakes) && (
      <CircularProgress />
    )} 
    {/* Event / Bet Details */}
    {(!loading && bet) && (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card>
            <UserCardHeader {...bet.user} />
            <CardContent>
              <BetDetails
                {...bet}
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <React.Fragment>
            {isBettor && (
              bet.is_settled ? (
                <Card style={{marginBottom: 6}}>
                  <CardContent>
                    You've marked this bet as settled.
                  </CardContent>
                </Card>
              ) : (
                  <Card style={{marginBottom: 6}}>
                    <CardContent>
                      The following users took this bet, you need to settle with them.
                    </CardContent>
                    <CardActions>
                    <Button onClick={()=>setShowMarkDialog(true)}>Mark as settled.</Button>
                  </CardActions>
                  </Card>
              )
            )}
            {takes.map(take => {
              return <TakeCard key={take._id} take={take} bet={bet} />
            })}
          </React.Fragment>
          {/* Bet settle dialog */}
          <MarkAsSettleDialog 
            bet={bet}
            open={showMarkDialog}
            onClose={()=>setShowMarkDialog(false)}
            settleBet={settleBet}
          />
          {/* Take settle dialog */}
          <MarkAsSettleTakeDialog
            take={myTake}
            open={showMarkTakeDialog}
            onClose={()=>setShowMarkTakeDialog(false)}
            settleTake={settleTake}
          />
          {myTake && (
            myTake.is_settled ? (
              <Card style={{marginTop: 6}}>
                <CardContent>
                  You marked this bet as settled.
                </CardContent>
              </Card>
            ) : (
              <Card style={{marginTop: 6}}>
                <CardContent>
                  You took {bet.user.display_name}'s bet for ${myTake.amount.toFixed(2)}. You need to settle with them.
                </CardContent>
                <CardActions>
                  <Button onClick={()=>setShowMarkTakeDialog(true)}>Mark ${myTake.amount.toFixed(2)} bet as settled.</Button>
                </CardActions>
              </Card>
            )
          )}
        </Grid>
      </Grid>
    )}
    </React.Fragment>
  )
}

export default Settle;