import { 
  createContext,
  useContext,
  useState
} from "react";

const toastContext = createContext();

export default function useToast() {
  return useContext(toastContext);
}

export function useProvideToast() {
  const [message, setMessage] = useState("");
  const [show, setShow] = useState(false);

  return {
    message,
    setMessage,
    show,
    setShow
  };
}

export function ProvideToast({ children }) {
  const toast = useProvideToast();
  return (
    <toastContext.Provider value={toast}>
      {children}
    </toastContext.Provider>
  );
}