import React from "react";
import { ThemeProvider } from '@material-ui/core/styles';
import { createMuiTheme } from '@material-ui/core/styles';

import { ProvideAuth } from "./Auth";
import { ProvideConversations } from "./Conversations";
import AuthRouter from "./AuthRouter";

import { Provider as StoreProvider } from "react-redux";
import { Store } from './redux/Store';

export const ROUTE_NAMES = {
  '/': 'Bookie',
  '/bet': 'Bet',
  '/settle': 'Settle Bet',
  '/new-bet': 'New Bet',
  '/forum': 'Forum',
  '/conversations': 'Chats',
  '/notifications': 'Notifications',
  '/account': 'Account',
  '/user': 'User',
  '/register': 'Register',
  '/bet-history': 'Bet History',
}

export const APP_VERSION = '1.80';

// Theme
const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#484848',
      main: '#212121',
      dark: '#000000',
      contrastText: '#ffffff',
    },
    secondary: {
      light: '#ffffff',
      main: '#f9f9f9',
      dark: '#c6c6c6',
      contrastText: '#000000',
    },
    unreadBlue: {
      main: '#0000FF'
    }
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <StoreProvider store={Store}>
        <ProvideAuth>
          <ProvideConversations>
            <AuthRouter />
          </ProvideConversations>
        </ProvideAuth>
      </StoreProvider>
    </ThemeProvider>
  );
}

export default App;
