// Messages API

import { ApiCore } from './utilities/core'
import { apiProvider } from './utilities/provider';

const url = 'messages';
const plural = 'messages';
const single = 'message';

// plural and single may be used for message logic if needed in the ApiCore class.

const apiMessages = new ApiCore({
  getAll: true,
  getSingle: true,
  post: true,
  put: false,
  patch: false,
  delete: false,
  url: url,
  plural: plural,
  single: single
});

/** @param {int} conversation_id */ 
/** @summary Get all messages for conversation id */
apiMessages.getAllForConversation = (conversation_id, token) => {
  // Add custom api call logic here
  return apiProvider.getAll(`${url}?conversation_id=${conversation_id}`, token);
}

export default apiMessages;