import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import Fab from '@material-ui/core/Fab';
import SendIcon from '@material-ui/icons/Send';
import CircularProgress from '@material-ui/core/CircularProgress';
import AppBar from '@material-ui/core/AppBar';
import moment from 'moment';

import apiMessages from '../services/api/messages';

import useAuth from '../Auth';
import { ListItemAvatar } from '@material-ui/core';
import { drawerWidth } from '../common/AppBarContainer';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  chatSection: {
    width: '100%',
    height: '80vh'
  },
  headBG: {
      backgroundColor: '#e0e0e0'
  },
  borderRight500: {
      borderRight: '1px solid #e0e0e0'
  },
  messageArea: {
    height: '69vh',
    overflowY: 'auto',
    position: 'relative',
    paddingTop: 12
  },
  chatBar: {
    bottom: 54,
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      bottom: 0
    },
    padding: 6,
    top: 'auto',
  },
  messageInput: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
}));

const PUBLIC_CONVERSATION_ID = 1

// for now, all messages are from the conversation 1 (public channel)
const Chats = () => {
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  const scrollRef = useRef(null);

  const classes = useStyles();

  let auth = useAuth();
  console.log("auth Chats: ", auth);

  useEffect(() => {
    _getMessages(PUBLIC_CONVERSATION_ID);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ behaviour: "smooth" });
    }
  }, [messages]);

  function _getMessages(conversationId) {
    setLoading(true);
    apiMessages.getAllForConversation(conversationId, auth.user.token).then(res => {
      let messages = res.messages
      console.log('messages: ', messages);
      setMessages(messages);
      setLoading(false);
    })
  }

  function sendMessage(conversationId) {
    apiMessages.post({conversation_id: conversationId, body: message}, auth.user.token)
    .then(res => {
      if (res.ok) {
        console.log("sendMessage: ", res);
        setMessage('');
        _getMessages(conversationId);
      }
    });
  }

  return (
    <div>
      <Grid 
        container
        // component={Paper}
        // className={classes.chatSection}
        >
          <Grid item xs={12}>
              <List>
                { loading && <CircularProgress style={{marginLeft: '50%'}} /> }
                {messages && messages.map((message, i) => {
                  // show this users message on right, other user messages on left
                  if (message.user._id === auth.user._id) {
                    return (
                      <ListItem key={message._id}>
                        <ListItemText 
                          align="right"
                          primary={message.body}
                          primaryTypographyProps={{ style: { wordBreak: "break-word", maxWidth: '60vw'} }}
                          secondary={message.user.display_name + ' | ' + moment.utc(message.timestamp).local().format('L, LT')}
                        />
                        <ListItemAvatar align="right">
                          <Avatar alt={message.user.display_name} src={message.user.picture} />
                        </ListItemAvatar>
                      </ListItem>
                    )
                  } else {
                    return (
                      <ListItem key={message._id}>
                        <ListItemAvatar>
                          <Avatar 
                            to={message.user._id ? `/user/${message.user._id}` : '/account'}
                            alt={message.user.display_name}
                            src={message.user.picture}
                            component={Link}
                          />
                        </ListItemAvatar>
                        <ListItemText 
                          align="left"
                          primary={message.body}
                          primaryTypographyProps={{ style: { wordBreak: "break-word", maxWidth: '60vw'} }}
                          secondary={message.user.display_name + ' | ' + moment.utc(message.timestamp).local().format('L, LT')}
                        />
                      </ListItem>
                    )
                  } 
                })}
                <ListItem ref={scrollRef}></ListItem>
              </List>
              {/* spacer for mobile */}
              <div style={{padding:10}}></div>
          </Grid>
      </Grid>
      <AppBar position="fixed" color="secondary" className={classes.chatBar}>
          <Grid container direction="row" alignItems="flex-end" spacing={3}>
              <Grid item xs={10}>
                <TextField
                  id="outlined-basic-email"
                  placeholder="Aa" 
                  autoComplete="off"
                  fullWidth
                  value={message}
                  onChange={(e)=>setMessage(e.target.value)}
                  onKeyPress={(ev) => {
                    if (ev.key === 'Enter') {
                      if (message.length>0) {
                        // Do code here
                        ev.preventDefault();
                        sendMessage(PUBLIC_CONVERSATION_ID);
                      }
                    }
                  }}
                />
              </Grid>
              <Grid item xs={2}>
                  <Fab 
                    color="primary" 
                    aria-label="add" 
                    disabled={message.length<1}
                    onClick={()=>{
                      sendMessage(PUBLIC_CONVERSATION_ID);
                    }}>
                      <SendIcon />
                  </Fab>
              </Grid>
          </Grid>
      </AppBar>
    </div>
  );
}

export default Chats;