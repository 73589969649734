// provider.js

import { handleResponse, handleError } from './response'; 

const BASE_URL = process.env.REACT_APP_API_ENDPOINT

/** @param {string} resource */ 
/** @param {string} token */ 
const getAll = (resource, token) => { 
  if (token) {
    let headers = new Headers();
    headers.append('Authorization', `Bearer ${token}`)
    return fetch(`${BASE_URL}/${resource}`, {headers: headers}) 
      .then(handleResponse) 
      .catch(handleError); 
  } else {
    return fetch(`${BASE_URL}/${resource}`) 
      .then(handleResponse) 
      .catch(handleError);
  } 
}; 

/** @param {string} resource */ 
/** @param {string} id */ 
/** @param {string} token */ 
const getSingle = (resource, id, token) => { 
  console.log('getSingle token ', token);
  let headers = new Headers();
  if (token) {
    headers.append('Authorization', `Bearer ${token}`)
    return fetch(`${BASE_URL}/${resource}/${id}`, {headers: headers})
      .then(handleResponse) 
      .catch(handleError); 
  } else {
    return fetch(`${BASE_URL}/${resource}/${id}`) 
      .then(handleResponse) 
      .catch(handleError); 
  }
}; 

/** @param {string} resource */ 
/** @param {object} model */ 
/** @param {string} token */ 
const post = (resource, model, token) => { 
  console.log('post token ', token);
  let headers = new Headers();
  if (token) {
    headers.append('Content-Type', 'application/json');   
    headers.append('Authorization', `Bearer ${token}`)
    return fetch(`${BASE_URL}/${resource}`, 
      {method: 'post', headers: headers, body: JSON.stringify(model)}) 
      .then(handleResponse) 
      .catch(handleError); 
  } else {
    headers.append('Content-Type', 'application/json');   
    return fetch(`${BASE_URL}/${resource}`, 
      {method: 'post', headers: headers, body: JSON.stringify(model)}) 
      .then(handleResponse) 
      .catch(handleError); 
  }
}; 

/** @param {string} resource */ 
/** @param {object} model */ 
/** @param {string} token */ 
const put = (resource, model, token) => { 
  console.log('put token ', token);
  let headers = new Headers();
  headers.append('Content-Type', 'application/json');
  if (token) {
    headers.append('Authorization', `Bearer ${token}`)
  }
  return fetch(`${BASE_URL}/${resource}`, 
    {method: 'put', headers: headers, body: JSON.stringify(model)}) 
    .then(handleResponse) 
    .catch(handleError); 
}; 

/** @param {string} resource */ 
/** @param {object} model */ 
/** @param {string} token */ 
const patch = (resource, model, token) => { 
  console.log('patch token ', token);
  let headers = new Headers();
  headers.append('Content-Type', 'application/json');
  if (token) {
    headers.append('Authorization', `Bearer ${token}`)
  }
  return fetch(`${BASE_URL}/${resource}`, 
    {method: 'patch', headers: headers, body: JSON.stringify(model)}) 
    .then(handleResponse) 
    .catch(handleError); 
}; 

/** @param {string} resource */ 
/** @param {string} id */ 
/** @param {string} token */ 
const remove = (resource, id, token) => { 
  console.log('remove token ', token);
  let headers = new Headers();
  headers.append('Content-Type', 'application/json');
  if (token) {
    headers.append('Authorization', `Bearer ${token}`)
  }
  return fetch(`${BASE_URL}/${resource}/${id}`, 
    {method: 'delete', headers: headers})  
    .then(handleResponse) 
    .catch(handleError); 
}; 

export const apiProvider = { 
  getAll, 
  getSingle, 
  post, 
  put, 
  patch, 
  remove, 
};