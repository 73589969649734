import React, { useState, useEffect } from 'react';
import {
  useParams
} from "react-router-dom";
import { 
  Typography,
  Grid
} from '@material-ui/core';

import useAuth from '../Auth';
import { UserCard } from './MyAccount';
import { UserBets } from '../betHistory/BetHistory';

import apiUsers from '../services/api/users';

export default function PublicProfile() {
  const [user, setUser] = useState({});

  let { userId } = useParams();
  let auth = useAuth();

  useEffect(() => {
    _getUser(userId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId])

  function _getUser(userId) {
    apiUsers.getSingle(userId, auth.user.token).then(res => {
      let user = res.user;
      console.log('user: ', user)
      setUser(user);
    });
  }
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <UserCard user={user}/>
      </Grid>
      <Grid item xs={12}>
        <Typography variant={'overline'}>Recent Bets</Typography>
        <UserBets bets={user.recent_bets}/>
      </Grid>
    </Grid>
  )
}