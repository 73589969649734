import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Fab from '@material-ui/core/Fab';
import Hidden from '@material-ui/core/Hidden';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Badge from '@material-ui/core/Badge';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import Avatar from '@material-ui/core/Avatar';
import { APP_VERSION, ROUTE_NAMES } from '../App';
import {
   Link, 
   useHistory 
} from "react-router-dom";

import bmb_logo_alpha from '../assets/bmb_logo_alpha.png';


// icons
import HomeIcon from '@material-ui/icons/Home';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import NotificationsIcon from '@material-ui/icons/Notifications';
import HistoryIcon from '@material-ui/icons/History';

import AccountCircle from '@material-ui/icons/AccountCircle';
import AddIcon from '@material-ui/icons/Add';

import useAuth from "../Auth";
import useToast, { ProvideToast } from '../Toast';

import { setBetStart } from '../redux/actions/BetActions';
import { connect } from "react-redux";
import useConversations from '../Conversations';

export const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  root: {
    display: 'flex',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    paddingLeft: 6,
    flexGrow: 1,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  bottomAppBar: {
    top: 'auto',
    bottom: 0,
  },
  bottomToolbar: {
    justifyContent: 'space-between'
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(2),
  },
  unreadChatBubble: {
    backgroundColor: theme.palette.unreadBlue.main
  }
}));

function TopAppBar({auth}) {
  // const [user, setUser] = useState({});
  const classes = useStyles();
  let history = useHistory();

  console.log(ROUTE_NAMES)

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  // useEffect(() => {
  //   // if auth updated, get new profile pic for menu bar
  //   _getMyUser();
  // }, [auth])

  // function _getMyUser() {
  //   if (auth.user) {
  //     apiUsers.getMyUser(auth.user.token).then(res => {
  //       let user = res.user;
  //       delete user._id;
  //       console.log('TopAppBar user: ', user);
  //       setUser(user);
  //     })
  //   }
  // }

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  let signout = () => {
      handleClose();
      let signout = auth.signout(() => {
        history.replace('/');
      });
      signout();
  }

  return (
    <AppBar className={classes.appBar} position="fixed" color="secondary">
      <Toolbar>
        <Link to="/">
          <img src={bmb_logo_alpha} alt="BMB Logo" style={{maxWidth: 45}}/>
        </Link>
        {/* <Typography variant="h6" className={classes.title}>
          {pageName && pageName}
        </Typography> */}
        <div className={classes.title}></div>
        {auth.user && (
          <div>
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              {(auth.user && auth.user.picture) ? (
                <Avatar src={auth.user.picture} alt={auth.user.display_name} />
              ) : (
                <AccountCircle />
              )}
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={open}
              onClose={handleClose}
            >
              <MenuItem 
                component={Link}
                to={'/account'}
                onClick={handleClose}>
                Account
              </MenuItem>
              <MenuItem onClick={signout}>Logout</MenuItem>
            </Menu>
          </div>
        )}
      </Toolbar>
    </AppBar>
  )
}

function AppSnackBar() {
  const toast = useToast();

  function handleClose() {
    toast.setShow(false)
  }

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={toast.show}
      autoHideDuration={5000}
      onClose={handleClose}
      message={toast.message}
      action={
        <React.Fragment>
          <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </React.Fragment>
      }
    />
  )
}

function LeftDrawer(props) {
  const classes = useStyles();
  const auth = useAuth();
  const {
    hasUnreadMessages
  } = useConversations();

  function getUnreadNotifs() {
    if (props.notifications) {
      let unreadNotifs = props.notifications.filter((notif) => (notif.is_read === false)).length;
      console.log("unread notifs: ", unreadNotifs);
      return unreadNotifs;
    }
    else {
      return [];
    }
  }

  const onClickNewBet = () => {
    const { betStartFlag, setBetStart } = props;
    setBetStart(!betStartFlag);
  }

  return (
    <Drawer
      className={classes.drawer}
      variant="permanent"
      anchor="left"
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <div className={classes.toolbar} />
      <Divider />
      <List>
      {auth.user ? (
        <React.Fragment>
          <ListItem button component={Link} to="/feed">
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary={"Bet Feed"} />
          </ListItem>
          <ListItem button component={Link} to="/chats">
            <ListItemIcon>
              <Badge variant="dot" classes={{badge: classes.unreadChatBubble}} invisible={!hasUnreadMessages}>
                <ChatBubbleIcon />
              </Badge>
            </ListItemIcon>
            <ListItemText primary={"Chats"} />
          </ListItem>
          <ListItem button component={Link} to="/new-bet" onClick={() => onClickNewBet()}>
            <ListItemIcon>
              <AddIcon />
            </ListItemIcon>
            <ListItemText primary={"New Bet"} />
          </ListItem>
          <ListItem button component={Link} to="/notifications">
            <ListItemIcon>
              <Badge color="error" badgeContent={getUnreadNotifs()}>
                <NotificationsIcon />
              </Badge>
            </ListItemIcon>
            <ListItemText primary={"Notifications"} />
          </ListItem>
          <ListItem button component={Link} to="/bet-history">
            <ListItemIcon>
              <HistoryIcon />
            </ListItemIcon>
            <ListItemText primary={"Bet History"} />
          </ListItem>
        </React.Fragment>
        ) : (
          <React.Fragment>
            <ListItem button component={Link} to="/">
              <ListItemIcon>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText primary={"Home"} />
            </ListItem>
          </React.Fragment>
        )}
      </List>
      <Divider />
      <List>
        <ListItem>
          <Typography variant="overline">Beta v{APP_VERSION}</Typography>
        </ListItem>
      </List>
    </Drawer>
  )
}

function BottomAppBar(props) {
  const classes = useStyles();
  const {
    hasUnreadMessages
  } = useConversations();

  function getUnreadNotifs() {
    if (props.notifications) {
      let unreadNotifs = props.notifications.filter((notif) => (notif.is_read === false)).length;
      console.log("unread notifs: ", unreadNotifs);
      return unreadNotifs;
    } else {
      return [];
    }
  }

  const onClickNewBet = () => {
    const { betStartFlag, setBetStart } = props;
    setBetStart(!betStartFlag);
  }

  // hide bottom app bar on chat page (commented out)
  // if (pageName !== 'Chat') {
  if (true) {
    return (
      <AppBar position="fixed" color="primary" className={classes.bottomAppBar}>
        <Toolbar className={classes.bottomToolbar}>
          <IconButton color="inherit" component={Link} to="/feed" >
            <HomeIcon />
          </IconButton>
          <IconButton color="inherit" component={Link} to="/chats">
            <Badge variant="dot" classes={{badge: classes.unreadChatBubble}}  invisible={!hasUnreadMessages}>
              <ChatBubbleIcon />
            </Badge>
          </IconButton>
          <Fab color="secondary" aria-label="add" component={Link} to="/new-bet" onClick={() => onClickNewBet()}>
            <AddIcon />
          </Fab>
          <IconButton color="inherit" component={Link} to="/notifications">
            <Badge color="error" badgeContent={getUnreadNotifs()}>
              <NotificationsIcon />
            </Badge>
          </IconButton>
          <IconButton color="inherit" component={Link} to="/bet-history">
            <HistoryIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
    )
  } else {
    return null
  }
}

function AppBarContainer(props) {
  const classes = useStyles();
  const auth = useAuth();

  return (
    // provide toast (snackbar) to all components in AppBarContainer
    <ProvideToast>
      <div className={classes.root}>
        {/* CSS baseline */}
        <CssBaseline />
        {/* top bar */}
        <TopAppBar auth={auth}/>
        {/* Drawer, hidden on mobile */}
        <Hidden xsDown>
          <LeftDrawer 
            notifications={props.notifications}
            setBetStart={props.setBetStart}
            betStartFlag={props.betStartFlag}
          />
        </Hidden>
        
        {/* main app content */}
        <main className={classes.content}>
          <div className={classes.toolbar} />
          {/* div wrapper to provide padding on bottom */}
          <div style={{paddingBottom: 60}}>
            { props.children }
          </div>
        </main>
        {/* bottom bar, hidden on desktop or if not authenticated */}
        {auth.user && (
          <Hidden smUp>
            <BottomAppBar 
              notifications={props.notifications} 
              setBetStart={props.setBetStart} 
              betStartFlag={props.betStartFlag}
            />
          </Hidden>
        )}
        {/* App-wide snackbar (toast) */}
        <AppSnackBar />
      </div>
    </ProvideToast>
  );
}

const mapStateToProps = state => ({
  betStartFlag: state.bet.betStartFlag,
  notifications: state.notification.notifications
});

export default connect(mapStateToProps, { setBetStart })(AppBarContainer);