import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from "react-router-dom";
import { Grid, Button, TextField, Fade, Typography } from '@material-ui/core';
import apiResetPassword from '../services/api/reset-password';
import bmb_logo from '../assets/bmb_logo_512.png';
import useToast from '../Toast';

function ResetPassword(props) {
  const search = useLocation().search;
  const history = useHistory();
  const [password, setPassword] = useState('');
  const [token, setToken] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [show, setShow] = useState(false);

  const toast = useToast();

  useEffect(() => {
    setShow(true);
  }, []);

  useEffect(() => {
    const token = new URLSearchParams(search).get('token');
    if(!is_valid(token)) {
      history.push('/');
    } else {
      setToken(token);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const is_valid = (token) => {
    if(token) {
      return true;
    }
    else {
      return false;
    }
  }

  const resetPassword = () => {
    const model = {
      token: token,
      password: password
    }
    apiResetPassword.post(model).then(response => {
      console.log({response});
      if (!response.ok) {
        toast.setMessage(response.message);
        toast.setShow(true);
        setPassword('');
        setRepeatPassword('');
      } else {
        toast.setMessage("Password reset");
        toast.setShow(true);
        history.push('/');
      }
    })
  }

  return (
    <Grid 
      container
      alignItems="center"
      justifyContent="center"
      direction="column"
      spacing={3}
    >
      <Grid item>
        <Fade in={show} {...(show ? { timeout: 500 } : {})}>
          <img style={{maxHeight: '40vh', maxWidth: '80vw', borderRadius: '10%'}} alt="BMB Logo" src={bmb_logo}/>
        </Fade>
      </Grid>
      <Grid item>
        <Fade
          in={show}
          {...(show ? { timeout: 1000 } : {})}
        >
          <Typography align="center">Please choose a new password.</Typography>
        </Fade>
      </Grid>
      <Grid item>
        <Fade
          in={show}
          {...(show ? { timeout: 1500 } : {})}
        >
          <TextField
            variant="outlined"
            label="Password"
            type="password"
            InputLabelProps={{
              shrink: true,
            }}
            value={password}
            onChange={(e)=> setPassword(e.target.value)}
          />
        </Fade>
      </Grid>
      <Grid item>
        <Fade
          in={show}
          {...(show ? { timeout: 1500 } : {})}
        >
          <TextField
            variant="outlined"
            label="Repeat Password"
            type="password"
            InputLabelProps={{
              shrink: true,
            }}
            value={repeatPassword}
            onChange={(e)=> setRepeatPassword(e.target.value)}
          />
        </Fade>
      </Grid>
      <Grid item style={{padding: 0}}>
        {password.length < 6 && password.length !== 0 ?  <p style={{lineHeight: 0, color: 'red'}}>Password should longer than 6 characters.</p>
        : password !== repeatPassword && repeatPassword.length !== 0 && <p style={{lineHeight: 0, color: 'red'}}>Password did not match.</p> }
      </Grid>
      <Grid item >
        <Button variant="outlined" disabled={password.length < 6 || password !== repeatPassword} onClick={resetPassword}>Reset Password</Button>
      </Grid>
    </Grid>
  )
}

export default ResetPassword;