// response.js

export function handleResponse(response) {
  // by default return the json
  console.log("handleResponse: ", response);
  if (response.ok) {
    return response.json();
  } 
  // throw error if not ok
  return response.json().then(res => {
    if (res.message) {
      return res;
    } else {
      throw new Error('API Error');
    }
  })
}

export function handleError(error) {
  console.log("Error was caught");
  if (error) {
    console.log(error);
    return error;
  }
  return error;
}