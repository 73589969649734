import { Fade, List, ListItem, ListItemIcon, ListItemText, Typography } from "@material-ui/core"
import { ChatBubble, Forum } from "@material-ui/icons"
import React, { useEffect, useState } from "react";
import { FiberManualRecord } from '@material-ui/icons';
import { Link } from "react-router-dom"
import { makeStyles } from '@material-ui/core';
import useConversations from "../Conversations";

const useStyles = makeStyles((theme) => ({
    unreadBadge: {
        color: '#0000FF',
    }
}));

export default function ChatList(props) {
  const classes = useStyles();
  const [show, setShow] = useState(false);
  const { hasUnreadMessages } = useConversations();

  useEffect(() => {
    setShow(true);
  }, [])

  return (
    <React.Fragment>
      <Fade in={show} {...(show ? { timeout: 1000 } : {})}>
        <Typography variant="h4">
          Chat Types
        </Typography>
      </Fade>
      <List>
        <Fade in={show} {...(show ? { timeout: 1250 } : {})}>
          <ListItem button component={Link} to='/conversations'>
            <ListItemIcon>
              <ChatBubble></ChatBubble>
            </ListItemIcon>
            <ListItemText primary="P2P Chats" secondary="Real-time chats with individual users" />
            {hasUnreadMessages && (
                <ListItemIcon className={classes.unreadBadge}><FiberManualRecord/></ListItemIcon>
            )}
          </ListItem>
        </Fade>
        <Fade in={show} {...(show ? { timeout: 1500 } : {})}>
          <ListItem button component={Link} to='/forum'>
            <ListItemIcon>
              <Forum></Forum>
            </ListItemIcon>
            <ListItemText primary="Forum" secondary="Public discussion for all users"/>
          </ListItem>
        </Fade>
      </List>
    </React.Fragment>
  )
}