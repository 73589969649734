import React, { useEffect, useState } from "react";
import { Typography } from "@material-ui/core";
import Conversation from "./Conversation";
import { ConversationsList } from "./ConversationsList";
import useAuth from "../Auth";
import { useParams } from "react-router-dom";
import useConversations from "../Conversations";

function ConversationsApp(props) {
  const [selectedConversation, setSelectedConversation] = useState();

  const { 
    conversations, 
    conversationStatus, 
    currConvoSid,
    setCurrConvoSid
  } = useConversations();

  let auth = useAuth();
  let { conversationId } = useParams();

  useEffect(() => {
    console.log({conversationId})
    if (conversationId && conversationId !== 'all') {
      setCurrConvoSid(conversationId);
    } else {
      setCurrConvoSid('');
    }
  }, [conversationId, currConvoSid, setCurrConvoSid])

  useEffect(() => {
    if (currConvoSid) {
      const convoTemp = conversations.find(
        (it) => it.sid === currConvoSid
      );
      setSelectedConversation(convoTemp);
    } else {
      setSelectedConversation(null);
    }
  }, [conversations, currConvoSid])

  return (
    selectedConversation ? (
      <React.Fragment>
        <Conversation
          conversationProxy={selectedConversation}
          myIdentity={auth.user._id}
        />
      </React.Fragment>
    ) : (
      conversationStatus !== "success" ? "Loading conversations" : (
        <React.Fragment>
          <Typography variant="h4">
            Conversations
          </Typography>
          <ConversationsList />
        </React.Fragment>
      )
    )
  )
}


export default ConversationsApp;