import React, { Component } from 'react';
// import Dropzone from 'react-dropzone';
import ConversationsMessages from "./ConversationsMessages";
import PropTypes from "prop-types";
import { AppBar, Fab, Grid, TextField } from '@material-ui/core';
import { Send } from '@material-ui/icons';
import { withStyles } from '@material-ui/styles';
import { drawerWidth } from '../common/AppBarContainer';

const styles = theme => ({
  chatBar: {
    bottom: 54,
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      bottom: 0
    },
    padding: 6,
    top: 'auto',
  },
});

class Conversation extends Component {
  constructor(props) {
    super(props);
    this.state = {
        newMessage: '',
        conversationProxy: props.conversationProxy,
        messages: [],
        loadingState: 'initializing',
        boundConversations: new Set()
    };
  }

  loadMessagesFor = (thisConversation) => {
    if (this.state.conversationProxy === thisConversation) {
        thisConversation.getMessages()
            .then(messagePaginator => {
                if (this.state.conversationProxy === thisConversation) {
                    this.setState({ messages: messagePaginator.items, loadingState: 'ready' });
                }
            })
            .catch(err => {
                console.error("Couldn't fetch messages IMPLEMENT RETRY", err);
                this.setState({ loadingState: "failed" });
            });
    }
  };

  componentDidMount = () => {
      if (this.state.conversationProxy) {
        this.loadMessagesFor(this.state.conversationProxy);

        if (!this.state.boundConversations.has(this.state.conversationProxy)) {
            let newConversation = this.state.conversationProxy;
            newConversation.on('messageAdded', m => this.messageAdded(m, newConversation));
            this.setState({boundConversations: new Set([...this.state.boundConversations, newConversation])});
        }
      }

      this.state.conversationProxy.on('typingStarted', function(participant) {
        console.log('typing started, ', participant);
        console.log(this.state.conversationProx, '=============')
      });

      this.state.conversationProxy.on('typingEnded', function(participant) {
        console.log('typing ended, ', participant);
      });
  }

  componentDidUpdate = (oldProps, oldState) => {
    if (this.state.conversationProxy !== oldState.conversationProxy) {
        this.loadMessagesFor(this.state.conversationProxy);

        if (!this.state.boundConversations.has(this.state.conversationProxy)) {
            let newConversation = this.state.conversationProxy;
            newConversation.on('messageAdded', m => this.messageAdded(m, newConversation));
            this.setState({boundConversations: new Set([...this.state.boundConversations, newConversation])});
        }
    }
  };

  static getDerivedStateFromProps(newProps, oldState) {
    let logic = (oldState.loadingState === 'initializing') || oldState.conversationProxy !== newProps.conversationProxy;
    if (logic) {
      return { loadingState: 'loading messages', conversationProxy: newProps.conversationProxy };
    } else {
      return null;
    }
  }

  messageAdded = (message, targetConversation) => {
    if (targetConversation === this.state.conversationProxy) {
        this.setState((prevState, props) => ({
            messages: [...prevState.messages, message]
        }));
      }
  };

  onMessageChanged = event => {
    this.setState({ newMessage: event.target.value });
  };

  sendMessage = () => {
    const message = this.state.newMessage;
    this.setState({ newMessage: '' });
    this.state.conversationProxy.sendMessage(message).then(resultIndex => {
      console.log({resultIndex});
      this.updateLastReadMessageIndex(resultIndex); // mark read with this idex
    });
  };

  updateLastReadMessageIndex = (resultIndex) => {
    this.state.conversationProxy.updateLastReadMessageIndex(resultIndex);
  }

  onDrop = acceptedFiles => {
    this.state.conversationProxy.sendMessage({contentType: acceptedFiles[0].type, media: acceptedFiles[0]});
  };

  render = () => {
    const { classes } = this.props;
    return (
      <React.Fragment>
          <ConversationsMessages
              identity={this.props.myIdentity}
              messages={this.state.messages}/>
        <AppBar 
          position="fixed" 
          color="secondary" 
          className={classes.chatBar}
        >
          <Grid container direction="row" alignItems="flex-end" spacing={3}>
              <Grid item xs={10}>
                <TextField
                  id="outlined-basic-email"
                  placeholder="Aa" 
                  autoComplete="off"
                  fullWidth
                  value={this.state.newMessage}
                  onChange={this.onMessageChanged}
                  onKeyPress={(ev) => {
                    if (ev.key === 'Enter') {
                      if (this.state.newMessage.length>0) {
                        // Do code here
                        ev.preventDefault();
                        this.sendMessage();
                      }
                    }
                    else {
                      this.state.conversationProxy.typing()
                    }
                  }}
                />
              </Grid>
              <Grid item xs={2}>
                  <Fab 
                    color="primary" 
                    aria-label="add" 
                    disabled={this.state.newMessage.length<1 || this.state.loadingState !== 'ready'}
                    onClick={()=>{
                      this.sendMessage();
                    }}>
                      <Send />
                  </Fab>
              </Grid>
          </Grid>
      </AppBar>
    </React.Fragment>
    );
  }
}

Conversation.propTypes = {
  myIdentity: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Conversation);