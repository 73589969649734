// Register API

import { ApiCore } from './utilities/core'

const url = 'register';
const plural = 'register';
const single = 'register';

// plural and single may be used for message logic if needed in the ApiCore class.

const apiRegister = new ApiCore({
  getAll: false,
  getSingle: false,
  post: true,
  put: false,
  patch: false,
  delete: false,
  url: url,
  plural: plural,
  single: single
});

export default apiRegister;