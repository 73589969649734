import React, { useState, useEffect } from 'react';
import {
  useParams,
  useHistory
} from "react-router-dom";
import { 
  CircularProgress, 
  Button,
  Typography,
  Card,
  CardContent,
  CardActions,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from '@material-ui/core';

import useAuth from '../Auth';
import { BetDetails, ReportButton, ShareButton } from '../common/BetCard';
import { UserCardHeader } from '../account/MyAccount';

import apiBets from '../services/api/bets';
import apiTakes from '../services/api/takes';
import useToast from '../Toast';
import TakeCard from './TakeCard';

const CancelBetDialog = (props) => {
  const [isCancelling, setIsCancelling] = useState(false);

  let auth = useAuth();
  const toast = useToast();
  const history = useHistory();

  function cancelBet() {
    setIsCancelling(true);
    apiBets.remove(props.betId, auth.user.token).then(res => {
      setIsCancelling(false);
      if (res.ok) {
        console.log(res);
        toast.setMessage("Your bet was cancelled.");
        toast.setShow(true);
        props.onClose();
        history.push("/home");
      } else {
        console.log("error:");
        console.log(res);
        toast.setMessage('Could not cancel bet.');
        toast.setShow(true);
      }
    });
  }

  return (
    <Dialog
      maxWidth={'md'}
      open={props.open}
      onClose={props.handleClose}
    >
      <DialogTitle>Cancel Unfilled Bet?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to cancel this unfilled bet? Your bet will no longer be viewable to others.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={isCancelling}
          onClick={props.onClose}
          variant="outlined"
        >
          No, do not cancel my bet
        </Button>
        <Button 
          disabled={isCancelling}
          onClick={cancelBet} 
          color="primary"
          variant="contained"
        >
          {isCancelling ? (
            <CircularProgress color="secondary" />
          ) : (
            "Yes cancel my bet"
          )}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const TakeBetDialog = (props) => {
  return (
    <Dialog
      maxWidth={'md'}
      open={props.open}
      onClose={props.handleClose}
    >
      <DialogTitle>Take Bet?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to take this bet?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={props.onClose}
          variant="outlined"
        >
          No, do not take bet
        </Button>
        <Button 
          onClick={() => props.takeBet(props.bet?._id, props.bet?.max_winning)} 
          color="primary"
          variant="contained"
        >
            Take {props.bet.type} bet for ${props.bet?.max_winning?.toFixed(2)} to win ${props.bet.amount}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const Bet = () => {
  const history = useHistory();
  const [bet, setBet] = useState({});
  const [takes, setTakes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingTakes, setLoadingTakes] = useState(true);
  const [showCancel, setShowCancel] = useState(false);
  const [showTake, setShowTake] = useState(false);

  let { betId } = useParams();

  let auth = useAuth();
  const toast = useToast();

  console.log("betId: ", betId);

  useEffect(() => {
    _getBet(betId);
    _getBetTakes(betId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [betId])

  function _getBet(betId) {
    setLoading(true);
    apiBets.getSingle(betId, auth.user.token).then(res => {
      let bet = res.bet;
      console.log('bet: ', bet)
      setBet(bet);
      setLoading(false);
    });
  }

  function _getBetTakes(betId) {
    setLoadingTakes(true);
    apiTakes.getAllForBet(betId, auth.user.token).then(res => {
      let takes = res.takes;
      console.log(`all takes for bet ${betId}: `, takes);
      setTakes(takes);
      setLoadingTakes(false);
    });
  }

  function takeBet(betId, amount) {
    setLoadingTakes(true);
    apiTakes.post({
      bet_id: betId,
      amount: amount
    }, auth.user.token).then(res => {
      _getBetTakes(betId);
      if (res.ok) {
        console.log(res);
        toast.setMessage("Your bet take was accepted!");
        toast.setShow(true);
        setShowTake(false);
        history.push('/feed');
      } else {
        console.log("apiBets.post error:");
        console.log(res);
        toast.setMessage(`ERROR: ${res.message}`);
        toast.setShow(true);
      }
    });
  }

  return (
    <React.Fragment>
    {(loading || loadingTakes) && (
      <CircularProgress />
    )} 
    {/* Error message for missing bets */}
    {!loading && !bet && (
      <Typography>
        Could not load bet.
      </Typography>
    )}
    {/* shoe cancel dialog */}
    {bet && (
      <CancelBetDialog
        betId={bet._id}
        open={showCancel}
        onClose={()=>setShowCancel(false)}
      />
    )}
    {bet && (
      <TakeBetDialog
        bet={bet}
        open={showTake}
        onClose={()=>setShowTake(false)}
        takeBet={takeBet}
      />
    )}
    {/* Event / Bet Details */}
    {(!loading && bet) && (
      <Card>
        <UserCardHeader {...bet.user} />
        <CardContent>
          <BetDetails
            {...bet}
          />
        </CardContent>
        <CardActions>
          { (bet.my_bet && !takes.length) && (
            <React.Fragment>
              <Button variant="outlined" onClick={()=>setShowCancel(true)}>
                Cancel Bet
              </Button>
              <Button variant="outlined" onClick={()=>history.push(`/bet/${betId}/edit`)}>
                Edit Bet
              </Button>
            </React.Fragment>
          )}
          <ReportButton id={betId} />
          <ShareButton id={betId} />
        </CardActions>
      </Card>
    )}
    {/* Bet Takes */}
    {(!loadingTakes && takes && bet) && (
      !bet.my_bet && (
        <Card style={{marginTop: 12, paddingTop: 12}}>
        <CardContent>
          <Typography variant={'overline'}>Take Bet</Typography><br/>
          {bet.my_take ? (
            <Button disabled={true} variant={'outlined'}>
              You've already taken this bet for {bet.my_take.amount.toFixed(2)}
            </Button>
          ) : (
            bet.take_total === bet.max_winning ? (
              <Button disabled={true} variant={'outlined'}>
                Bet is already filled
              </Button>
            ) : (
              <Button variant={'outlined'} onClick={()=>setShowTake(true)}>
                Take {bet.type} bet for ${bet.max_winning.toFixed(2)} to win ${bet.amount.toFixed(2)}
              </Button>
            )
          )}
        </CardContent>
        </Card>
      )
    )} 
    {(!loadingTakes && takes && bet) && (
      <Grid container spacing={3} style={{marginTop: 12}}>
        <Grid item xs={12}>
          {bet.my_bet ? (
            <Typography variant={'overline'}>
              My Bet Takes
            </Typography>
          ) : (
            <Typography variant={'overline'}>
              Bet Takes
            </Typography>
          )}
        </Grid>
        <Grid item xs={12}>
          {takes.map((take) => {
            console.log("take: ", take);
            return <TakeCard key={take._id} take={take} bet={bet} />
          })}
          </Grid>
      </Grid>
    )}
    </React.Fragment>
  );
}

export default Bet;