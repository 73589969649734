// response.js

export function handleResponse(response) {
  // by default return the json
  if (response.ok) {
    return response.json();
  }
  // throw error if not ok
  throw new Error('Network response was not ok.');
}

export function handleError(error) {
  if (error) {
    return error;
  }
  return error;
}