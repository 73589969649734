// Auth API

import { ApiCore } from './utilities/core'
import { apiProvider } from './utilities/provider';

const url = 'auth';
const plural = 'auth';
const single = 'auth';

// plural and single may be used for message logic if needed in the ApiCore class.

const apiAuth = new ApiCore({
  getAll: false,
  getSingle: false,
  post: true,
  put: false,
  patch: false,
  delete: false,
  url: url,
  plural: plural,
  single: single
});

/** @summary Refresh access token */
apiAuth.refresh = (token) => {
  // post empty model
  return apiProvider.post(`${url}/refresh`, {}, token);
}

export default apiAuth;