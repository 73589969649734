import React from 'react';
import { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles' 
import { connect } from "react-redux";
import Grid from '@material-ui/core/Grid';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import Paper from '@material-ui/core/Paper';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Hidden from '@material-ui/core/Hidden';
import moment from 'moment';

import { Link } from "react-router-dom";

import useAuth from '../Auth';

import EventCard from './EventCard.js';
import apiTeams from '../services/the-sports-db-api/teams';
import apiBets from '../services/api/bets';
import apiOdds from '../services/api/odds';
import useToast from '../Toast';
import BetCard, { BetDetails, TeamAvatar } from '../common/BetCard';
import { List, ListItem, ListItemAvatar, ListItemText, MobileStepper } from '@material-ui/core';


// new bet defaults
const NEW_BET = {
  user_id: 0, 
  winner_team: 0,
  spread: 1,
  points_scored: 0,
  over_under: '',
  sport_key: '',
  team_1: 0,
  team_2: 0,
  type: "Moneyline",
  team_1_odds: 100,
  team_2_odds: -100,
  amount: 100,
  multiple_takes: false, // can bet be split?
  min_take: 100, // if bet can be split, what is minimum amount user can take?
}  

// sport icons
const SPORT_ICONS = [
  {
    key: 'baseball_mlb',
    name: 'MLB',
    icon: 'sports/mlb.svg'
  },
  {
    key: 'basketball_nba',
    name: 'NBA',
    icon: 'sports/nba.jpeg'
  },
  {
    key: 'americanfootball_nfl',
    name: 'NFL',
    icon: 'sports/nfl.png'
  },
  {
    key: 'basketball_ncaab',
    name: 'NCAABB',
    icon: 'sports/ncaa_bb.png'
  },
  {
    key: 'americanfootball_ncaaf',
    name: 'NCAAF',
    icon: 'sports/ncaa_fb.png'
  },
  {
    key: 'icehockey_nhl',
    name: 'NHL',
    icon: 'sports/nhl.png'
  }
]

const useStyles = makeStyles((theme) => ({
  img: {
    objectFit: 'contain',
    margin: 6,
  }
}));

function SportStep({sportKey, setSportKey, sportIcons}) {

  return (
    <React.Fragment>
      <List>
      { sportIcons.map((sport, i) => (
        <ListItem 
          button
          onClick={(event)=>setSportKey(sport.key)}
          selected={sport.key === sportKey}
          key={sport.key}
        >
          <ListItemAvatar>
            <TeamAvatar variant="rounded" alt={sport.key} src={sport.icon}/>
          </ListItemAvatar>
          <ListItemText primary={sport.name + (sport.key === sportKey ? " (Selected)" : '')}/>
        </ListItem>
      
      ))}
      </List>

      {/* //   <Grid container spacing={3}>
      //     { sportIcons.map((sport, i) => (
      //       <Grid key={sport.key} item xs={6} sm={4} md={2} onClick={()=>setSportKey(sport.key)}>
      //           <Avatar classes={{img:classes.img}} style={{boxShadow: 2}} variant="rounded" alt={sport.key} src={sport.icon}/>
      //         {sport.key === sportKey && "(Selected)"}
      //       </Grid>
      //     ))}
      // </Grid> */}
    </React.Fragment>
  )
}

function EventStep({sportKey, newBet, setNewBet, setSelectedEvent, handleNext, showCustom, setShowCustom}) {

  const [teamStr, setTeamStr] = useState("");
  const [upcomingEvents, setUpcomingEvents] = useState([]);
  const [loading, setLoading] = useState(false);
  
  let auth = useAuth();

  // for debounce search

  useEffect(() => {
    _getUpcomingEventsBySport("", sportKey);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sportKey])

  function _getUpcomingEventsBySport(team, sportKey) {
    setLoading(true);
    apiOdds.getAllForTeamAndSport(team, sportKey, auth.user.token).then((res) => {
      let upcoming = res.odds;
      setUpcomingEvents(upcoming);
      console.log(upcoming);
      setLoading(false);
    });
  }

  const searchByTeam = (teamStr) => {
    setTeamStr(teamStr);
    _getUpcomingEventsBySport(teamStr, sportKey);
  }

  const selectEvent = (event, betType, team_1_odds, team_2_odds, selected_team='', spread=1, over_under='', points_scored=0) => {
    // if both team odds are negative, make even odds
    let team_1_odds_mid;
    let team_2_odds_mid
    if (team_1_odds < 0 && team_2_odds < 0) {
      team_1_odds_mid = 100;
      team_2_odds_mid = 100;
    } else {
      // else calculate average 
      let averge_odds = Math.round((Math.abs(team_1_odds) + Math.abs(team_2_odds)) / 2);
      team_1_odds_mid = Math.sign(team_1_odds) * averge_odds;
      team_2_odds_mid = Math.sign(team_2_odds) * averge_odds;
    }
    setNewBet({
      ...newBet,
      sport_key: event.sport_key,
      team_1: event.home_team,
      team_2: event.away_team,
      event_id: event.id,
      datetime: event.commence_time,
      type: betType,
      winner_team: selected_team,
      spread: spread,
      over_under: over_under,
      points_scored: points_scored,
      team_1_odds: team_1_odds_mid,
      team_2_odds: team_2_odds_mid
    });
    setSelectedEvent(event);
    handleNext();
    handleNext();
  }

  // not working
  // function debounce(func, timeout = 300){
  //   console.log("timer", timer);
  //   console.log("func", func);
  //   return (...args) => {
  //     clearTimeout(timer);
  //     timer = setTimeout(() => { func.apply(this, args) }, timeout);
  //   };
  // }

  return (
    <React.Fragment>
    { showCustom ? (
      <React.Fragment>
        <Button onClick={()=>setShowCustom(false)}>
          Search Existing Events
        </Button>
        <Paper style={{padding:12}}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant={'overline'}>Teams</Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Team 1"
                  value={newBet.team_1 || ''}
                  onChange={(e)=>setNewBet({...newBet, team_1: e.target.value})}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Team 2"
                  value={newBet.team_2 || ''}
                  onChange={(e)=>setNewBet({...newBet, team_2: e.target.value})}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant={'overline'}>Date</Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  id="datetime-local"
                  label="Date / Time"
                  type="datetime-local"
                  value={newBet.datetime || moment().toISOString()}
                  onChange={(e)=>{setNewBet({...newBet, datetime: e.target.value})}}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>
          </Paper>
      </React.Fragment>
      ) : (
      <React.Fragment>
        <TextField
          label="Search by Team"
          variant="outlined"
          value={teamStr}
          onChange={(e)=>searchByTeam(e.target.value)}
          fullWidth
        />
        <Button onClick={()=>setShowCustom(true)}>
          Can't Find Event?
        </Button>
        { loading && (
          <CircularProgress />
        )}
        { upcomingEvents && (
          upcomingEvents.map((event) => (
            <EventCard
              key={event._id}
              {...event}
              event={event}
              selectEvent={selectEvent}
              displayOnly={false}
            />
          )
        ))}
      </React.Fragment>
    )}
    </React.Fragment>
  )
}


function BetType({newBet, setBetType}) {
  const betTypes = ['Moneyline', 'Spread', 'Totals'];

  return (
    <React.Fragment>
      <Typography variant="caption">
        Select Bet Type
      </Typography>
      <Grid container spacing={3}>
        {betTypes.map(bet => (
          <Grid item key={bet} xs={12}>
            <Button 
              fullWidth
              onClick={()=>setBetType(bet)}
              color="primary"
              variant={newBet.type === bet ? "contained" : "outlined"}
            >
              {bet}
            </Button>
          </Grid>
        ))}
      </Grid>
    </React.Fragment>
  )
}

export function calcWinnings(team_1, team_1_odds, team_2, team_2_odds, winner_team, type, amount, to_risk) {
  const team_1_winner = team_1 === winner_team;
  let winnerOdds = team_1_winner ? team_1_odds : team_2_odds;

  if (type === 'Moneyline' || type === 'Spread') {
    // payout is proportional to  odds
    if (winnerOdds > 0) {
      return (amount * winnerOdds / 100.0).toFixed(2);
    } else if (winnerOdds < 0) {
      return (amount * 100.0 / -winnerOdds).toFixed(2);
    }
  } else if (type === 'Totals') {
    // point totals odds are team1Odds
    // if risking use team 2 odds
    if (to_risk){
      if (team_2_odds > 0) {
        return (amount * team_2_odds / 100.0).toFixed(2);
      } else if (team_2_odds < 0) {
        return (amount * 100.0 / -team_2_odds).toFixed(2);
      }
    } else {
      if (team_1_odds > 0) {
        return (amount * team_1_odds / 100.0).toFixed(2);
      } else if (team_1_odds < 0) {
        return (amount * 100.0 / -team_1_odds).toFixed(2);
      }
    }
    
  }
}

export function calcTakeWinnings(team_1, team_1_odds, team_2, team_2_odds, winner_team, type, amount) {
  const team_1_winner = team_1 === winner_team;
  const winnerOdds = team_1_winner ? team_2_odds : team_1_odds;

  if (type === 'Moneyline' || type === 'Spread') {
    // payout is proportional to  odds
    if (winnerOdds > 0) {
      return (amount * winnerOdds / 100.0).toFixed(2);
    } else if (winnerOdds < 0) {
      return (amount * 100.0 / -winnerOdds).toFixed(2);
    }
  } else if (type === 'Totals') {
    // point totals take odds are team2Odds
    if (team_2_odds > 0) {
      return (amount * team_2_odds / 100.0).toFixed(2);
    } else if (team_2_odds < 0) {
      return (amount * 100.0 / -team_2_odds).toFixed(2);
    }
  }
}

export function BetStep({newBet, setNewBet, teams}) {
  const [odds1Input, setOdds1Input] = useState(newBet.team_1_odds);
  const [odds2Input, setOdds2Input] = useState(newBet.team_2_odds);
  const [spreadInput1, setSpreadInput1] = useState(newBet.spread);
  const [spreadInput2, setSpreadInput2] = useState(-newBet.spread);
  const [toWinAmount, setToWinAmount] = useState(0);

  const classes = useStyles();

  useEffect(() => {
    const selectedTeam = newBet.winner_team === newBet.team_1 ? newBet.team_1 : newBet.team_2;
    console.log(newBet.amount, 'newBet.amount');
    console.log(toWinAmount, 'toWinAamount before');
    let tempWinAmount = parseFloat(calcWinnings(newBet.team_1, newBet.team_1_odds, newBet.team_2, 
      newBet.team_2_odds, selectedTeam, newBet.type, newBet.amount));
    setToWinAmount(tempWinAmount);
    console.log(tempWinAmount, 'toWinAamount after');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newBet.amount, newBet.selectedTeam, newBet.team_1_odds, newBet.team_2_odds, 
    newBet.team_1, newBet.team_2, newBet.type, newBet.winner_team])

  const onChangeOdds1 = (e) => {
    // when editing, allow any input gets converted to int on blur
    setOdds1Input(e.target.value);
  }

  const onBlurOdds1 = () => {
    let newOdds = parseInt(odds1Input);
    if (newOdds < 100 && newOdds >= 0) {
      newOdds = 100;
    } else if (newOdds > -100 && newOdds <= 0) {
      newOdds = -100;
    }
    setOdds1Input(newOdds);
    setOdds2Input(-newOdds);
    setNewBet({...newBet, team_1_odds: newOdds, team_2_odds: -newOdds});
  }

  const onChangeOdds2 = (e) => {
    // when editing, allow any input gets converted to int on blur
    setOdds2Input(e.target.value);
  }

  const onBlurOdds2 = () => {
    let newOdds = parseInt(odds2Input);
    if (newOdds < 100 && newOdds >= 0) {
      newOdds = 100;
    } else if (newOdds > -100 && newOdds <= 0) {
      newOdds = -100;
    }
    setOdds2Input(newOdds);
    setOdds1Input(-newOdds);
    setNewBet({...newBet, team_1_odds: -newOdds, team_2_odds: newOdds});
  }

  const onBlurSpread1 = () => {
    let newSpread = parseFloat(spreadInput1);
    if (newSpread > 0 || newSpread < 0) {
      setNewBet({...newBet, spread: newSpread});
      setSpreadInput2(-newSpread);
    } else {
      // revert to 1 if invalid input
      setSpreadInput1(1);
      setSpreadInput2(-1);
      setNewBet({...newBet, spread: 1});
    }
  }

  const onBlurSpread2 = () => {
    let newSpread = parseFloat(spreadInput2);
    if (newSpread > 0 || newSpread < 0) {
      setNewBet({...newBet, spread: -newSpread});
      setSpreadInput1(-newSpread);
    } else {
      // revert to 1 if invalid input
      setSpreadInput1(1);
      setSpreadInput2(-1);
      setNewBet({...newBet, spread: 1});
    }
  }

  const onBlurToWin = () => {
    const winAmount = parseFloat(toWinAmount);
    const selectedTeam = newBet.winner_team === newBet.team_1 ? newBet.team_2 : newBet.team_1;
    const riskAmount = parseFloat(calcWinnings(newBet.team_1, newBet.team_1_odds, newBet.team_2, 
      newBet.team_2_odds, selectedTeam, newBet.type, winAmount, newBet.over_under));
    setToWinAmount(winAmount);
    setNewBet({...newBet, amount: riskAmount});
  }

  return (
    <React.Fragment>
        <Typography variant="subtitle1" gutterBottom>
          Bet Details
        </Typography>
        <Grid container spacing={3}>
          <Grid item container spacing={3} xs={12}>
            <Grid item container xs={12}>
              {/* Moneyline & spread odds input */}
              { (newBet.type === 'Moneyline' || newBet.type === 'Spread') && (
                <React.Fragment>
                  <Grid item xs={6}>
                    <Avatar classes={{img:classes.img}} variant="rounded" alt={newBet.team_2} src={`sports/teams/${newBet.team_2}.png`}/>
                    <Typography>{newBet.team_2}</Typography>
                    <Typography gutterBottom>
                      Odds {(newBet.team_2_odds > 0 ? '+' : '') + newBet.team_2_odds}
                    </Typography>
                    <TextField
                      label="Odds"
                      type="number"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={odds2Input}
                      onChange={onChangeOdds2}
                      onBlur={onBlurOdds2}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Avatar classes={{img:classes.img}} variant="rounded" alt={newBet.team_1} src={`sports/teams/${newBet.team_1}.png`}/>
                    <Typography>{newBet.team_1}</Typography>
                    <Typography gutterBottom>
                      Odds {(newBet.team_1_odds > 0 ? '+' : '') + newBet.team_1_odds}
                    </Typography>
                    <TextField
                      label="Odds"
                      type="number"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={odds1Input}
                      onChange={onChangeOdds1}
                      onBlur={onBlurOdds1}
                    />
                  </Grid>
                </React.Fragment>
              )}

              {/* Totals odds input, only team_1_odds used */}
              { newBet.type === 'Totals' && (
                <Grid item xs={12}>
                  <Typography gutterBottom>
                    Odds {(newBet.team_1_odds > 0 ? '+' : '') + newBet.team_1_odds}
                  </Typography>
                  <TextField
                    label="Odds"
                    type="number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={odds1Input}
                    onChange={onChangeOdds1}
                    onBlur={onBlurOdds1}
                  />
                </Grid>
              )}
              
            </Grid>
          </Grid>
          <Grid item xs={12}>
          { (newBet.team_1_odds > 0 && newBet.team_2_odds > 0) && (
            <Typography color="secondary">
              Invalid odds, one team must be one underdog.
            </Typography>
          )}
          { (newBet.team_1_odds < 0 && newBet.team_2_odds < 0) && (
            <Typography color="secondary">
              Invalid odds, one team must be one favorite.
            </Typography>
          )}
          </Grid>
          { newBet.type === 'Moneyline' && (
            <Grid item xs={12}>
              <FormLabel component="legend">Winner Selection</FormLabel>
              <RadioGroup 
                aria-label="winner team" 
                name="winner team" 
                value={newBet.winner_team} 
                onChange={(e)=>setNewBet({...newBet, winner_team: e.target.value})}>
                <FormControlLabel
                  value={newBet.team_2} 
                  control={<Radio color="primary" />}
                  label={newBet.team_2} />
                <FormControlLabel 
                  value={newBet.team_1} 
                  control={<Radio color="primary" />}
                  label={newBet.team_1}/>
              </RadioGroup>
            </Grid>
          )}
          { newBet.type === 'Spread' && (
            <Grid item xs={12}>
              <FormLabel component="legend">Team & Spread Points</FormLabel>
              <RadioGroup 
                name="team selection" 
                value={newBet.winner_team} 
                onChange={(e)=>setNewBet({...newBet, winner_team: e.target.value})}>
                {/* spread is always for team 1 */}
                <Grid container>
                  <Grid item>
                    <FormControlLabel 
                      value={newBet.team_2} 
                      control={<Radio color="primary" />} 
                      label={`${newBet.team_2}`} />
                  </Grid>
                  <Grid item>
                  <TextField
                      disabled={newBet.winner_team !== newBet.team_2}
                      fullWidth
                      // label="Point Spread"
                      type="number"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={spreadInput2}
                      onChange={(e) => setSpreadInput2(e.target.value)}
                      onBlur={onBlurSpread2}
                    />
                  </Grid>
                </Grid>
                <Grid  container xs={12}>
                  <Grid item >
                    <FormControlLabel 
                      value={newBet.team_1} 
                      control={<Radio color="primary" />} 
                      label={`${newBet.team_1}`}
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      disabled={newBet.winner_team !== newBet.team_1}
                      fullWidth
                      // label="Point Spread"
                      type="number"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={spreadInput1}
                      onChange={(e) => setSpreadInput1(e.target.value)}
                      onBlur={onBlurSpread1}
                    />
                  </Grid>
                </Grid>
              </RadioGroup>
            </Grid>
          )}
          { newBet.type === 'Totals' && (
            <React.Fragment>
              <Grid item xs={12}>
                <TextField
                  id="totals"
                  label="Totals"
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={newBet.points_scored}
                  // we can directly set totals because it's not validated (beyond number)
                  onChange={(e)=>setNewBet({...newBet, points_scored: e.target.value})}
                />
              </Grid>
              <Grid item xs={12}>
                <FormLabel component="legend" style={{alignItems:'center'}}>
                  Over/Under
                </FormLabel>
                <RadioGroup 
                  aria-label="over-under" 
                  name="over under" 
                  value={newBet.over_under} 
                  onChange={(e)=>{
                    // update O/U but also swap odds
                    setOdds1Input(-odds1Input);
                    setNewBet({
                      ...newBet, 
                      over_under: e.target.value,
                      team_1_odds: newBet.team_2_odds, team_2_odds: newBet.team_1_odds
                    });
                  }}>
                  <FormControlLabel value={'over'} control={<Radio color="primary" />} label={"Over"}/>
                  <FormControlLabel value={'under'} control={<Radio color="primary" />} label={"Under"} />
                </RadioGroup>
              </Grid>
            </React.Fragment>
          )}
          <Grid item container xs={12}>
            <Grid item xs={6}>
              <TextField
                id="bet-amount"
                label="Risking"
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
                value={newBet.amount || ''}
                onChange={(e)=>{
                  const riskAmount = parseInt(e.target.value);
                  setNewBet({...newBet, amount: riskAmount});
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="to-win"
                label="To Win"
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
                value={toWinAmount}
                onChange={(e)=>{
                  setToWinAmount(e.target.value);
                }}
                onBlur={onBlurToWin}
              />
            </Grid>
          </Grid>

          {/* Bet Splitting (Disabled for now) */}
          {/* <Grid item xs={12}>
            <FormLabel component="legend" style={{alignItems:'center'}}>
            <Grid container direction="row" alignItems="center">
              <Grid item>
                Allow Bet Splitting?
              </Grid>
              <Grid item>
                <Tooltip title="Bet Splitting allows you to split you bet into multiple smaller bets. This is useful if you don't think someone will take your entire bet at once.">
                  <HelpIcon />
                </Tooltip>
              </Grid>
            </Grid>
            </FormLabel>
            <RadioGroup 
              aria-label="multi-takes" 
              name="multi takes" 
              value={newBet.multiple_takes} 
              onChange={(e)=>{
                console.log(e.target.value);
                let allow = e.target.value === "true"
                // if multiple takes, default to 20, else lock min_take to amount
                if (allow) {
                  setNewBet({...newBet, multiple_takes: allow, min_take: 20});
                } else {
                  setNewBet({...newBet, multiple_takes: allow, min_take: newBet.amount});
                }

              }}>
              <FormControlLabel value={true} control={<Radio color="primary" />} label={"Yes"}/>
              <FormControlLabel value={false} control={<Radio color="primary" />} label={"No"} />
            </RadioGroup>
          </Grid> */}
          {/* {newBet.multiple_takes && (
            <Grid item xs={12}>
              <FormLabel component="legend" style={{alignItems:'center'}}>
              <Grid container direction="row" alignItems="center">
              <Grid item>
                  Minimum Take Amount
                </Grid>
                <Grid item>
                  <Tooltip title="If you allow bet splitting you must also specify a minimum amount that someone can take your bet for. The maximum amount is 1/2 of the bet amount (split into 2 bets).">
                    <HelpIcon />
                  </Tooltip>
                </Grid>
              </Grid>
              </FormLabel>
              <TextField
                // label="Minimum Take Amount"
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
                value={newBet.min_take}
                onChange={onChangeTake}
                onBlur={onBlurTake}
              />
            </Grid>
          )} */}
        </Grid>
    </React.Fragment>
  )
}

function BetConfirmation({newBet, loading}) {

  return (
    <React.Fragment>
      {loading ? (
        <CircularProgress />
      ) : (
        <React.Fragment>
          <Typography variant="overline">
            Your Bet Details
          </Typography>
          <BetDetails
            {...newBet}
          />
        </React.Fragment>
        // <Grid container spacing={3}>
        //   <Grid item xs={12}>
        //     <Typography variant="overline">
        //       {newBet.team_1}
        //     </Typography>
        //     <Avatar classes={{img:classes.img}} variant="rounded" alt={newBet.team_1} src={newBet.team_1}/>
        //     <span style={{padding: 6}}>{newBet.team_1} {newBet.team_1_odds > 0 && '+'}{newBet.team_1_odds}</span>
        //   </Grid>
        //   <Grid item xs={12}>
        //     <Typography variant="overline">
        //       {newBet.team_2}
        //     </Typography>
        //     <Avatar classes={{img:classes.img}} variant="rounded" alt={newBet.team_2} src={newBet.team_2}/>
        //     <span style={{padding: 6}}>{newBet.team_2} {newBet.team_2_odds > 0 && '+'}{newBet.team_2_odds}</span>
        //   </Grid>
        //   <Grid item xs={12}>
        //     <Typography variant="overline">
        //       Date & Time: {newBet.datetime}
        //     </Typography>
        //   </Grid>
        //   <Grid item xs={12}>
        //     <Typography variant="overline">
        //       Bet Type: {newBet.type}
        //     </Typography>
            
        //   </Grid>
        //   <Grid item xs={12}>
        //     <Typography variant="overline">
        //       Amount: ${newBet.amount}
        //     </Typography>
        //   </Grid>
        // </Grid>
      )}
    </React.Fragment>
  )
}

function Done({newBet, newBetId}) {
  const[loading, setLoading] = useState(false);
  const[bet, setBet] = useState(null);

  const auth = useAuth();

  useEffect(() => {
    _getBet(newBetId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function _getBet(betId) {
    setLoading(true);
    apiBets.getSingle(betId, auth.user.token).then(res => {
      let bet = res.bet;
      console.log('bet: ', bet)
      setBet(bet);
      setLoading(false);
    });
  }

  return (
    <React.Fragment>
      <Typography variant="overline">
        Your bet has posted!
      </Typography>
      { loading && (
        <CircularProgress />
      )}
      { (!loading && bet) && (
        <BetCard
          user={bet.user}
          key={bet._id}
          id={bet._id}
          {...bet}
        />
      )}
    </React.Fragment>
  )
}

function getSteps() {
  return ['Sport', 'Event', 'Bet Type', 'Terms', 'Confirm'];
}

function NewBet(props) {
  const [newBet, setNewBet] = useState(NEW_BET);
  const [newBetId, setNewBetId] = useState("");
  const [teams, setTeams] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [betPostLoading, setBetPostLoading] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState({});
  const [sportKey, setSportKey] = useState("");
  const [showCustom, setShowCustom] = useState(false);
  const steps = getSteps();

  let auth = useAuth();
  const toast = useToast();

  useEffect(() => {
    _getTeams(newBet.leagueId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newBet.leagueId])
  
  useEffect(() => {
    setActiveStep(0);
    setSelectedEvent({});
    setSportKey("");
    setShowCustom(false);
    setBetPostLoading(false);
    setTeams([]);
    setNewBet(NEW_BET);
    setNewBetId("");
  }, [props.betStartFlag])

  function _getTeams(leagueId) {
    apiTeams.getAllForLeague(leagueId, auth.user.token).then((res) => {
      let teams = res.teams;
      setTeams(teams);
    });
  }

  function postBet() {
    setBetPostLoading(true);
    apiBets.post(newBet, auth.user.token).then(res => {
      if (res.ok) {
        console.log(res);
        setNewBetId(res.bet._id);
        setBetPostLoading(false);
        toast.setMessage("Your bet has posted!");
        toast.setShow(true);
        // only navigate to success screen if bet posted
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      } else {
        console.log("apiBets.post error:");
        console.log(res);
        setNewBetId("");
        toast.setMessage(`ERROR: ${res.message}`);
        toast.setShow(true);
        setBetPostLoading(false);
      }
    })
  }

  function setBetType(betType) {
    if (betType !== "Moneyline") {
      // reset odds
      setNewBet({...newBet, team_1_odds: 0, team_2_odds: 0, type: betType})
    } else {
      setNewBet({...newBet, type: betType})
    }
  }

  // function getStepContent(step) {
  //   switch (step) {
  //     case 0:
  //       return <LeagueStep loading={leaguesLoading} leagues={leagues} newBet={newBet} setNewBet={setNewBet}/>
  //     case 1:
  //       return <TeamDateStep loading={teamsLoading} teams={teams} newBet={newBet} setNewBet={setNewBet}/>
  //     case 2:
  //       return <BetType newBet={newBet} setNewBet={setNewBet}/>
  //     case 3:
  //       return <BetStep newBet={newBet} setNewBet={setNewBet} teams={teams}/>
  //     case 4:
  //       return <BetConfirmation newBet={newBet} teams={teams}/>
  //     case 5:
  //       return <Done loading={betPostLoading}/>
  //     default:
  //       return 'Unknown step'
  //   }
  // }

  function getStepContent(step) {
    switch (step) {
      case 0:
        return <SportStep sportKey={sportKey} setSportKey={setSportKey} sportIcons={SPORT_ICONS}/>
      case 1:
        return (
          <React.Fragment>
            { renderButtons() }
            <EventStep sportKey={sportKey} newBet={newBet} setNewBet={setNewBet} setSelectedEvent={setSelectedEvent} handleNext={handleNext} showCustom={showCustom} setShowCustom={setShowCustom}/>
          </React.Fragment>
        )
      case 2:
        return <BetType newBet={newBet} setBetType={setBetType}/>
      case 3:
        return <BetStep newBet={newBet} setNewBet={setNewBet} selectedEvent={selectedEvent} teams={teams}/>
      case 4:
        return <BetConfirmation newBet={newBet} loading={betPostLoading}/>
      case 5:
        return <Done newBetId={newBetId} newBet={newBet}/>
      default:
        return 'Unknown step'
    }
  }

  function handleNext() {
    if (activeStep === steps.length - 1) {
      postBet();
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  function canContinueNextStep() {
    // select sport screen
    if (activeStep === 0) {
      if (!sportKey) {
        return false;
      } else {
        return true;
      }
    } else if (activeStep === 1) {
      // event select
      if (showCustom) {
        return true
      } else {
        if (Object.keys(selectedEvent).length > 0) {
          return true;
        } else {
          return false;
        }
      }
    } else if (activeStep === 3) {
      // can only continue if bet side is taken
      if (newBet.type === "Moneyline" && (!newBet.winner_team)) {
        return false;
      } else if (newBet.type === "Spread" && (!newBet.winner_team)) {
        return false;
      } else if (newBet.type === "Totals" && (!newBet.over_under)) {
        return false;
      } else {
        return true;
      }
    } else {
      // can always continue otherwise
      return true;
    }
  }

  function handleBack() {
    // reset event and bet details if needed
    if (activeStep <= 1) {
      setNewBet(NEW_BET);
      setSelectedEvent({});
    }
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  function renderButtons() {
    return(
      <div>
        { activeStep < steps.length ? (
          <div style={{padding: 12}}>
            <Button disabled={activeStep === 0} onClick={handleBack}>
              Back
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={!canContinueNextStep()}
              onClick={handleNext}
            >
              {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
            </Button>
          </div>
        ) : (
          <div style={{padding: 12}}>
            <Button variant="outlined" component={Link} to="/" type="button">
              Home
            </Button>
          </div>
        )}
      </div>
    )
  }

  function renderHorizontalStepper() {
    return (
      <React.Fragment>
      <Stepper  style={{marginBottom: 12}} activeStep={activeStep}>
        {steps.map((label, index) => {
          return (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {/* Show selected Event */}
      {(Object.keys(selectedEvent).length > 0) && (
        <Paper style={{padding: 12, marginBottom: 6}}>
          <Typography variant={"caption"}>Selected Event</Typography>
          <EventCard
            {...selectedEvent}
            event={selectedEvent}
            displayOnly={true}
            newBet={newBet}
          />
        </Paper>
      )}
      <Paper style={{padding: 12}}>
        { getStepContent(activeStep) }
      </Paper>
      { renderButtons() }
      </React.Fragment>
    )
  }

  function renderMobileStepper() {
    return (
      <React.Fragment>
        <Typography variant="overline">STEP: {steps[activeStep]}</Typography>
        <br/>
        {/* Show selected Event */}
        {(Object.keys(selectedEvent).length > 0) && (
          <Paper style={{padding: 12, marginBottom: 6}}>
            <Typography variant={"caption"}>Selected Event</Typography>
            <EventCard
              {...selectedEvent}
              event={selectedEvent}
              displayOnly={true}
              newBet={newBet}
            />
          </Paper>
        )}
        { getStepContent(activeStep) }
        <MobileStepper
          steps={steps.length}
          position="static"
          variant="dots"
          activeStep={activeStep} 
          nextButton={
            activeStep < steps.length ? (
              <Button
                variant="contained"
                color="primary"
                onClick={handleNext}
                disabled={!canContinueNextStep()}
              >
                {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
              </Button>
              ) : (
                null
              )
          }
          backButton={
            activeStep < steps.length ? (
              <Button disabled={activeStep === 0} onClick={handleBack}>
                Back
              </Button>
            ) : (
              <Button variant="outlined" component={Link} to="/" type="button">
                Home
              </Button>
            )
          }
        />

      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <Hidden smDown>
        { renderHorizontalStepper() }
      </Hidden>
      <Hidden mdUp>
        { renderMobileStepper() }
      </Hidden>
    </React.Fragment>
  )
}

const mapStateToProps = state => ({
  betStartFlag: state.bet.betStartFlag,
});

export default connect(mapStateToProps, { })(NewBet);