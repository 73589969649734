import { 
    SET_NOTIFICATIONS
} from "../actions/NotificationActions";

const initialState = {
    notifications: [],
};

const NotificationReducer = function(state = initialState, action) {
    switch (action.type) {
        case SET_NOTIFICATIONS: {
            return {
                ...state,
                notifications: action.data
            };
        }
        default: {
            return state;
        }
    }
}

export default NotificationReducer;