import React from 'react';
import { 
  Typography,
  Card,
  CardContent,
  Chip
} from '@material-ui/core';

import { UserCardHeader } from '../account/MyAccount';

import { calcTakeWinnings } from '../newBet/NewBet';
import { makerDidWin, SideDetail } from '../common/BetCard';

export default function TakeCard({take, bet}) {

  return (
    <Card key={take._id}>
      {/* just pass in all user props for now, they shoud all be there */}
      <UserCardHeader {...take.user}/>
        <CardContent>
          {bet.completed && (makerDidWin(bet.type, bet.scores, bet.team_1, bet.team_2, bet.winner_team, bet.team_1_odds, bet.team_2_odds, bet.spread, bet.points_scored, bet.over_under) === true ? (
            <Chip size="small" color="primary" label={`${take.user.display_name} LOST`} variant="outlined"/> 
          ) : (
            makerDidWin(bet.type, bet.scores, bet.team_1, bet.team_2, bet.winner_team, bet.team_1_odds, bet.team_2_odds, bet.spread, bet.points_scored, bet.over_under) === "push" ? (
              <Chip size="small" color="primary" label="PUSH" variant="outlined"/>
            ) : (
            <Chip size="small" color="primary" label={`${take.user.display_name} WON`} variant="outlined"/>
          ))
          )}
          <br/>
          <Typography variant="overline">Took bet for ${take.amount > 0 && take.amount.toFixed(2)} to win ${calcTakeWinnings(bet.team_1, bet.team_1_odds, bet.team_2, bet.team_2_odds, bet.winner_team, bet.type, take.amount)}</Typography>
          <br/>
          <SideDetail {...bet} take={true}/>
        </CardContent>
  </Card>
  )
} 