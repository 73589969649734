import {
    SET_BET_START,
} from "../actions/BetActions";

const initialState = {
    betStartFlag: true,
};

const BetReducer = function(state = initialState, action) {
    switch (action.type) {
        case SET_BET_START: {
            return {
                ...state,
                betStartFlag: action.data
            };
        }
        default: {
            return state;
        }
    }
}

export default BetReducer;