import React from 'react';
import { connect } from "react-redux";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemAvatar';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import ReceiptIcon from '@material-ui/icons/Receipt';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import Divider from '@material-ui/core/Divider';
import { Skeleton } from '@material-ui/lab';
import {
  Link
} from "react-router-dom";
import moment from 'moment';

import apiNotifications from '../services/api/notifications';
import { setNotifications } from '../redux/actions/NotificationActions';

import useAuth from '../Auth';

function NotificationSkeleton() {
  return(
    <ListItem>
      <ListItemIcon>
        <Skeleton animation="wave" variant="circle" height={40} width={40} />
      </ListItemIcon>
      <ListItemText
        primary={<Skeleton animation="wave" height={40} width="100%" />}
        secondary={<Skeleton animation="wave" height={10} width="20%"  />}
      />
    </ListItem>
  )
}

function NotificationItem(props) {
  let auth = useAuth();
  const { notifications, setNotifications } = props;

  function updateNotificationReadStatus(notifications, notif_id) {
    let updatedNotifications = notifications;
    console.log(notif_id, 'notification id');
    setNotifications([]);
    updatedNotifications.map((notification, index) => {
      if(notification._id === notif_id) {
        updatedNotifications[index] = {
          ...notification,
          is_read: true
        }
      }
      return 0;
    });
    console.log(updatedNotifications, 'updated notifications');
    setNotifications(updatedNotifications);
  }

  function readNotification(notif_id) {
    console.log(`marking notif ${notif_id} as read`)
    apiNotifications.readNotification(notif_id, auth.user.token)
    updateNotificationReadStatus(notifications, notif_id);
  }

  // depending on type, will render different notification styles/behaviors
  if (props.type === 'bet') {
    return (
      <ListItem button onClick={()=>readNotification(props._id)} component={Link} to={`/bet/${props.bet_id}`}>
        <ListItemIcon>
          <ReceiptIcon color={props.is_read ? "inherit": "error"}/>
        </ListItemIcon>
        <ListItemText primaryTypographyProps={!props.is_read && ({ style:{ fontWeight: 600} })} primary={props.message} secondary={moment.utc(props.timestamp).local().format('L, LT')} />
        {!props.is_read && (<FiberManualRecordIcon color="error"/>)}
      </ListItem>
    )
  } else if (props.type === 'take') {
    return (
      <ListItem button onClick={()=>readNotification(props._id)} component={Link} to={`/bet/${props.bet_id}`}>
        <ListItemIcon>
          <AttachMoneyIcon color={props.is_read ? "inherit": "error"}/>
        </ListItemIcon>
        <ListItemText primaryTypographyProps={!props.is_read && ({ style:{ fontWeight: 600} })} primary={props.message} secondary={moment.utc(props.timestamp).local().format('L, LT')} />
        {!props.is_read && (<FiberManualRecordIcon color="error"/>)}
      </ListItem>
    )
  } else if (props.type === 'settle') {
    return (
      <ListItem button onClick={()=>readNotification(props._id)} component={Link} to={`/settle/${props.bet_id}`}>
        <ListItemIcon>
          <AttachMoneyIcon color={props.is_read ? "inherit": "error"}/>
        </ListItemIcon>
        <ListItemText primaryTypographyProps={!props.is_read && ({ style:{ fontWeight: 600} })} primary={props.message} secondary={moment.utc(props.timestamp).local().format('L, LT')} />
        {!props.is_read && (<FiberManualRecordIcon color="error"/>)}
      </ListItem>
    )
  } else {
    return null
  }
}

function Notifications(props) {
  const { loadingNotifications, notifications, setNotifications } = props;
  return (
    <List>
      { loadingNotifications && (
        <NotificationSkeleton />
      )

      }
      { notifications && (
        notifications.map(notification => (
          <React.Fragment key={notification._id}>
            {/* pass along entire notification as props */}
            <NotificationItem
              notifications={notifications}
              setNotifications={setNotifications}
              {...notification}
            />
            <Divider />
          </React.Fragment>
        ))
      )

      }
    </List>
  )
}

const mapStateToProps = state => ({
  notifications: state.notification.notifications
});

export default connect(mapStateToProps, { setNotifications })(Notifications);