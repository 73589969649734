// Reset Password API

import { ApiCore } from './utilities/core'
import { apiProvider } from './utilities/provider';

const url = 'reset-password';
const plural = 'reset-password';
const single = 'reset-password';

// plural and single may be used for message logic if needed in the ApiCore class.

const apiResetPassword = new ApiCore({
  getAll: false,
  getSingle: false,
  post: true,
  put: false,
  patch: false,
  delete: false,
  url: url,
  plural: plural,
  single: single
});

/** @param {Object} model */ 
/** @summary Request password reset */
apiResetPassword.request = (model) => {
  return apiProvider.post(`${url}-request`, model);
}

export default apiResetPassword;