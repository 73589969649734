// NOtification API

import { ApiCore } from './utilities/core'
import { apiProvider } from './utilities/provider';

const url = 'notifications';
const plural = 'notifications';
const single = 'notification';

// plural and single may be used for message logic if needed in the ApiCore class.

const apiNotifications = new ApiCore({
  getAll: true,
  getSingle: true,
  post: true,
  put: false,
  patch: true,
  delete: true,
  url: url,
  plural: plural,
  single: single
});

// custom api functions
/** @param {int} notification_id */ 
/** @summary Read notification */
apiNotifications.readNotification = (notification_id, token) => {
  // Add custom api call logic here
  return apiProvider.getAll(`${url}/${notification_id}`, token);
}

export default apiNotifications;