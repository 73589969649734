export const SET_CONVERSATIONS = "SET_CONVERSATIONS";
export const SET_CONVERSATION_STATUS = "SET_CONVERSATION_STATUS";

export function setConversations(conversations) {
    return dispatch => {
        dispatch({
            type: SET_CONVERSATIONS,
            conversations: conversations
        });
    };
}

export function setConversationStatus(conversationStatus) {
    return dispatch => {
        dispatch({
            type: SET_CONVERSATION_STATUS,
            conversationStatus: conversationStatus
        })
    }
}
