// Bet API

import { ApiCore } from './utilities/core'
import { apiProvider } from './utilities/provider';

const url = 'takes';
const plural = 'takes';
const single = 'take';

// plural and single may be used for message logic if needed in the ApiCore class.

const apiTakes = new ApiCore({
  getAll: true,
  getSingle: true,
  post: true,
  put: false,
  patch: true,
  delete: true,
  url: url,
  plural: plural,
  single: single
});

// custom api functions

/** @param {int} bet_id */ 
/** @summary Get all takes for a given bet id */
apiTakes.getAllForBet = (bet_id, token) => {
  return apiProvider.getAll(`${url}?bet_id=${bet_id}`, token);
}

/** @param {int} take_id */ 
/** @summary Settle take */
apiTakes.settleTake = (take_id, token) => {
  return apiProvider.put(`${url}/${take_id}/settle`, {}, token);
}

export default apiTakes;