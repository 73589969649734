// Odds API

import { ApiCore } from './utilities/core'
import { apiProvider } from './utilities/provider';

const url = 'odds';
const plural = 'odds';
const single = 'odd';

// plural and single may be used for message logic if needed in the ApiCore class.

const apiOdds = new ApiCore({
  getAll: true,
  getSingle: true,
  post: false,
  put: false,
  patch: false,
  delete: false,
  url: url,
  plural: plural,
  single: single
});


/** @param {string} team */ 
/** @summary Get all odds for given team */
apiOdds.getAllForTeam = (team, token) => {
  return apiProvider.getAll(`${url}?teams_like=${team}`, token);
}

/** @param {string} team */ 
/** @param {string} sport_key */ 
/** @summary Get all odds for given team with sport key */
apiOdds.getAllForTeamAndSport = (team, sport_key, token) => {
  return apiProvider.getAll(`${url}?teams_like=${team}&sport_key=${sport_key}`, token);
}

export default apiOdds;