import React, { useEffect, useState } from 'react';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { ListItemIcon } from '@material-ui/core';
import { FiberManualRecord } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core';
import useAuth from '../Auth';
import useConversations from '../Conversations';

const useStyles = makeStyles((theme) => ({
    unreadBadge: {
        color: '#0000FF',
    }
}))

const ConversationListItem = ({conversation}) => {
    const classes = useStyles();
    const [otherParticipant, setOtherParticipant] = useState({});
    const [lastMessage, setLastMessage] = useState({});

    const {
        setHasUnreadMessages,
        currConvoSid,
        checkHasUnreadMessage
    } = useConversations();

    let auth = useAuth();

    useEffect(() => {
        const checkMessages = m => {
            conversation.getMessages(1).then(messages => {
                messages.items.map(message => {
                    console.log({message})
                    setLastMessage(message);
                    return '';
                })
            })
        }
        const getParticipants = () => {
            conversation.getParticipants().then(participants => {
                console.log({participants});
                participants.map(p => {
                    // console.log({p});
                    if (p.identity !== auth.user._id) {
                        setOtherParticipant(p);
                    }
                    return p;
                })
                
            })
        }
        console.log("getting participants");
        getParticipants();
        console.log("getting more recent message");
        checkMessages();
        console.log("getting # unread messages");
        conversation.getUnreadMessagesCount().then(count => {
            console.log({count});
            if (count > 0) {
                setHasUnreadMessages(true);
            }
        })
        // console.log("lastReadMessageIndex:");
        // console.log(conversation.lastReadMessageIndex);

        conversation.on('messageAdded', m => checkMessages(m));
    }, [conversation, auth.user._id, setHasUnreadMessages, currConvoSid])

    const updateLastReadMessageIndex = () => {
        console.log("updating all messages read")
        conversation.setAllMessagesRead().then(count => {
            console.log("num messages read: ", count);
            checkHasUnreadMessage();
        })
    }

    return (
        <ListItem 
            alignItems="flex-start"
            to={`/conversations/${conversation.sid}`}
            component={Link}
            onClick={updateLastReadMessageIndex}
        >
            <ListItemAvatar>
                <AvatarGroup max={2}>
                    {(otherParticipant && otherParticipant.attributes) && (
                        <Avatar key={otherParticipant.sid} alt={otherParticipant.attributes.display_name} src={otherParticipant.attributes.picture} />
                    )}
                </AvatarGroup>
            </ListItemAvatar>
            <ListItemText
            primary={otherParticipant ? otherParticipant?.attributes?.display_name : 'User'}
            secondary={ lastMessage && (
                <React.Fragment>
                <Typography
                    component="span"
                    variant="body2"
                    color="textPrimary"
                >
                    {conversation.lastMessage && moment.utc(conversation.lastMessage.dateCreated).local().format('L, LT')}
                </Typography>
                {lastMessage.body && ` - ${lastMessage.body}`}
                </React.Fragment>
            )}
            />
            {conversation && conversation.lastMessage && conversation.lastReadMessageIndex < conversation.lastMessage.index && (
                <ListItemIcon className={classes.unreadBadge}><FiberManualRecord/></ListItemIcon>
            )}
            
        </ListItem>
    )
}

export function ConversationsList() {
    const { conversations } = useConversations();

    const sortConversations = (a, b) => {
        // console.log({a});
        // console.log({b});
        let aDate = (a.lastMessage ? a.lastMessage.dateCreated : a.dateUpdated);
        let bDate = (b.lastMessage ? b.lastMessage.dateCreated : b.dateUpdated);
        return (aDate > bDate ? -1 : 1);
    }
    
    return (
        <List >
            { conversations.sort(sortConversations).map(conversation => {
                console.log({conversation});
                return (
                    <React.Fragment key={conversation.sid}>
                        <ConversationListItem 
                            conversation={conversation}
                            getParticipants={conversation.getParticipants}
                        />
                        <Divider variant="inset" component="li" />
                    </React.Fragment>
                    )
                }
            )}
        </List> 
    )
}