import {
    SET_CONVERSATIONS,
    SET_CONVERSATION_STATUS,
} from "../actions/ConversationActions";

const initialState = {
    conversations: [],
    conversationStatus: ""
};

const ConversationReducer = function(state = initialState, action) {
    switch (action.type) {
        case SET_CONVERSATIONS: {
            return {
                ...state,
                conversations: action.conversations
            };
        }
        case SET_CONVERSATION_STATUS: {
            return {
                ...state,
                conversationStatus: action.conversationStatus
            }
        }
        default: {
            return state;
        }
    }
}

export default ConversationReducer;